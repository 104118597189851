import { all, delay, fork, put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  FIND_HIGH_TO_ROW_REQUEST,
  FIND_HIGH_TO_ROW_SUCCESS,
  FIND_HIGH_TO_ROW_FAILUR, //

} from '../reducers/anal';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'saga_anal';

// confirm 확인시 회차 정보 가져오기. 
function findHighToRowApi(fromWeekNo, toWeekNo) {
  // nicework.co.kr/api/lotto/show_num_cnt/100/1000
  debug(DEBUG_TYPE, '[ anal saga ] findHighToRowApi() axios 호출 ', `${fromWeekNo} ~ ${toWeekNo}`)
  const url = `/api/lotto/show_num_cnt/${fromWeekNo}/${toWeekNo}`
  const responsDatas = axios.get(url);
  return responsDatas;
}
function* findHighToRow(action) {
  debug(DEBUG_TYPE, '[ anal saga ] findHighToRow() 호출 ', action.data)
  try {
    const rangeWeekno = action.data // { fromWeekNo : 100, toWeekNo : 1000 }
    // range 구간 조회 
    const fromWeekNo = rangeWeekno.fromWeekNo;
    const toWeekNo = rangeWeekno.toWeekNo;
    const result = yield call(findHighToRowApi, fromWeekNo, toWeekNo);  // return type = {}
    const datas = result.data;
    // 전체 구간 조회 
    const firstWeekNo = 1
    const maxWeekNo = rangeWeekno.maxWeekNo;
    const totResult = yield call(findHighToRowApi, firstWeekNo, maxWeekNo);  // return type = {}
    const totDatas = totResult.data;
    debug(DEBUG_TYPE, '변경 전 totDatas ', totDatas)
    debug(DEBUG_TYPE, '변경 전 datas ', datas)
    // 배열 A의 각 요소를 순회하면서 num 값이 일치하는 배열 B의 요소를 찾아 total 값을 업데이트
    datas.forEach(itemRange => {
      const matchingItemTotal = totDatas.find(itemTotal => itemTotal.num === itemRange.num);
      if (matchingItemTotal) {
        itemRange.total = matchingItemTotal.value;
      }
    });
    debug(DEBUG_TYPE, '변경 후 ', datas)

    yield put({
      type: FIND_HIGH_TO_ROW_SUCCESS,
      data: datas
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: FIND_HIGH_TO_ROW_FAILUR,
      error: err.response.data,
    });
  }
}


function* watchFindHighToRow() {
  yield takeLatest(FIND_HIGH_TO_ROW_REQUEST, findHighToRow);
}
/*

*/
export default function* userSaga() {
  yield all([
    fork(watchFindHighToRow),
  ]);
}
