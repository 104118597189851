import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Container,
  Paper,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ButtonGroup,
} from '@mui/material';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ImageIcon from '@mui/icons-material/Image';



import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import WorkIcon from '@mui/icons-material/Work';
import ResultContent from 'components/lotto-result/ResultContent';
import Slip from 'components/lotto-mynum/Slip';

import { LOTTO_MINE, findData } from 'utils/dbCommon';

import { debug } from 'utils/common';
import { CLOSE_BTN_COLOR, QR_BTN_COLOR, DEL_BTN_COLOR, SAVE_BTN_COLOR, APP_BAR_BACKGROUND_COLOR, MYNUM_BTN_COLOR } from 'utils/constValue';
const DEBUG_TYPE = 'WeekWinConfirm_'

/**
 * 사용하는 화면에서 Dialog 로 사용됨. 
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
});

const WeekWinConfirm = (props) => {
  const open = props.open;
  const handleClose = props.handleClose;
  const handleSave = props.handleSave;
  const handleDelete = props.handleDelete;
  const handleQrOpen = props.handleQrOpen;
  const weekData = props.weekData;
  const newYn = props.newYn;
  const confirmType = props.confirmType;
  const propsSlipDatas = props.slipDatas;
  const title = props.title;
  const auditFunc = props.auditFunc;
  const [slipDatas, setSlipDatas] = useState([]);  // Array type

  useEffect(() => {
    debug(DEBUG_TYPE, '[ WeekWinConfirm ] props ', props)
    // const propsSlipDatas = props.slipDatas;
    // const propsConfirmType = props.confirmType;
    // const propsNewYn = props.newYn;
    // setSlipDatas(propsSlipDatas);
    // if (propsConfirmType === 'QR') {
    //   debug(DEBUG_TYPE, '[ WeekWinConfirm ] props : [ useEffect ] has propsNewYn', propsNewYn);
    //   debug(DEBUG_TYPE, '[ WeekWinConfirm ] props : [ useEffect ] has propsSlipDatas', propsSlipDatas);
    // }
  }, [props]);

  // slipDatas 가 변하면 저장할 데이타인지 확인 차 
  // useEffect(() => {
  //   debug(DEBUG_TYPE, '[ WeekWinConfirm ] propsSlipDatas', propsSlipDatas);

  // }, []);
  // =============================================================================
  // css
  // =============================================================================
  const descCss = useMemo(() => {
    return {
      marginTop: '24px',
      fontSize: '18px',
      textAlign: 'center',
    }
  }, [])

  const qrBtnStyle = useMemo(() => {
    return {
      backgroundColor: QR_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const saveBtnStyle = useMemo(() => {
    return {
      backgroundColor: SAVE_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const delBtnStyle = useMemo(() => {
    return {
      backgroundColor: DEL_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const closeBtnStyle = useMemo(() => {
    return {
      backgroundColor: CLOSE_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const fixBtnStyle = useMemo(() => {
    return {
      backgroundColor: MYNUM_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const appBarStyle = useMemo(() => {
    return {
      backgroundColor: APP_BAR_BACKGROUND_COLOR, // 원하는 텍스트 색상
    }
  }, []);
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} style={appBarStyle}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" style={{ marginTop: '10px' }}>
          <ResultContent weekData={weekData} history={false} />
        </Container>

        <Container>
          <List>
            {
              propsSlipDatas.map((item, index) => (
                <ListItem key={index} sx={{ px: '0px' }}>
                  <Slip key={item.trCode} slipData={item} />
                </ListItem>
              ))
            }
            {
              confirmType === 'QR' ? (
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                  style={{ marginTop: '10px' }}
                  fullWidth={true}
                >
                  <ThemeProvider theme={theme}>
                    {newYn === 'Y' ? (
                      <Button
                        style={saveBtnStyle}
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                      >저장</Button>
                    )
                      : (
                        <Button
                          style={delBtnStyle}
                          startIcon={<DeleteForeverIcon />}
                          onClick={handleDelete}
                        >삭제</Button>
                      )
                    }
                    <Button
                      style={qrBtnStyle}
                      startIcon={<QrCodeIcon />}
                      variant="contained"
                      onClick={handleQrOpen}
                    // component={Link} to="/Home/WinConfirm/1111"
                    >QR</Button>
                    <Button
                      style={closeBtnStyle}
                      startIcon={<CancelIcon />}
                      variant="contained"
                      onClick={handleClose}
                    // component={Link} to="/Home/WinConfirm/1111"
                    >딛기</Button>
                  </ThemeProvider>
                </ButtonGroup>
              ) : (null)
            }
            {
              (propsSlipDatas.length <= 0) ? (
                <div style={descCss}>
                  <span > 구매내역이 존재하지 않습니다.</span>
                </div>
              ) : null
            }
          </List>
        </Container>

      </Dialog >
    </>
  )
}

export default WeekWinConfirm