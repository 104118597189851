
import { combineReducers } from 'redux';

import user from './user';
import home from './home';
import saveNum from './saveNum';
import sample from './sample';
import storelotto from './storelotto';
import anal from './anal';

// (이전상태, 액션) => 다음상태
const rootReducer = combineReducers({
  user,
  home,
  saveNum,
  sample,
  storelotto,
  anal,
});


export default rootReducer;
