import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';
// import CloseIcon from '@mui/icons-material/Close';
// import Slide from '@mui/material/Slide';
// import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Container, ButtonGroup, Paper } from '@mui/material';
// import { PhotoCamera } from '@mui/icons-material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HomeIcon from '@mui/icons-material/Home';
import { purple } from '@mui/material/colors';

import ResultContent from 'components/lotto-result/ResultContent';
import BuyResult from 'components/buy-result/BuyResult';
import { FIND_WEEK_REQUEST, CONFIRM_DIALOG_OPEN, CONFIRM_LOTTO_REQUEST, CONFIRM_AUDIT_REQUEST, } from 'reducers/home';

import WeekWinConfirm from 'components/lotto-result/WeekWinConfirm';
import MessageAlert from 'components/MessageAlert';

import { curWeekNo, auditProc } from 'utils/lottoCommon';
import { cameraScan, dataParser } from 'utils/brige.js';
import { debug } from 'utils/common';
import { LOTTO_MINE, findData, insertData, deleteData } from 'utils/dbCommon';
// import { delay } from 'redux-saga/effects';

import { CONFIRM_BTN_COLOR, QR_BTN_COLOR, APP_BAR_BACKGROUND_COLOR } from 'utils/constValue';

const DEBUG_TYPE = 'Home';
// const DEBUG_TYPE = 'DEBUG';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });


const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
});


const Home = () => {

  // State 변수 
  const [openMsg, setOpenMsg] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [weekNo, setWeekNo] = useState(0);
  const [lastWeekNo, setLastWeekNo] = useState(0);  // 가장 

  // const [confirmArrayData, setConfirmArrayData] = useState([]); //당첨확인시 전달하는 값
  const [confirmType, setConfirmType] = useState('WEEK'); // QR/WEEK QR확인인지, WEEK확인인
  const [newYn, setNewYn] = useState('N');

  const [initYn, setInitYn] = useState("Y");
  const [audit, setAudit] = useState([]);
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  // Store 변수.
  const {
    confirmDialogOpened,
    confirmLottoData,
    weekData,
    confirmWeekData,
    auditData, auditLoading, auditDone, auditError
  } = useSelector(state => state.home);  // Array Type
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 해당 컴포넌트(=view) 마운트 시점에 실행됨. 
  // useEffect(() => {
  //   async function fetchData() {
  //     // You can await here
  //     const response = await MyAPI.getData(someId);
  //     // ...
  //   }
  //   fetchData();
  // }, [someId]); // Or [] if effect doesn't need props or state
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  async function auditFunc() {
    // 전체 조회하여 audit 만들기 
    const keyName = 'key';
    const key = '%';
    const allDatas = await findData(LOTTO_MINE, keyName, key);
    //
    debug(DEBUG_TYPE, '[ auditFunc ] allDatas : ', allDatas);
    //
    dispatch({
      type: CONFIRM_AUDIT_REQUEST,
      data: allDatas
    });
  }
  useEffect(() => {
    debug(DEBUG_TYPE, "[ Home ] component mounted .............................")
    // 초기 화면 오픈시 저장된 자료를 가져와 aduit 한다. 
    debug(DEBUG_TYPE, "[ Home ] initYn : ", initYn);
    // 구매내역 정산
    auditFunc();
    // 마지막 추첨 회자 구하기 ( 2002.12.01 ~ 현재일까지 몇주차 ?)
    const findedCurWeekNo = curWeekNo();
    debug(DEBUG_TYPE, "[ Home ] 마지막 추첨 회차 구하기 ", findedCurWeekNo)
    setWeekNo(findedCurWeekNo);        // 현재 회차 
    setLastWeekNo(findedCurWeekNo);    // 마지감 추첨 회차  
    debug(DEBUG_TYPE, "[ Home ] weekNo : ", weekNo);

    // 상단 weekNo 회차의 날짜 및 당첨번호 조회하기. 
    const findTypeWeekno = {
      type: 'DIRECT',
      weekNo: findedCurWeekNo
    }
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
    });

    // 브리지 함수를 전역으로 등록하여 웹뷰에서 호출할 수 있게 만듧
    window.CallBack = CallBack;
    return () => {
      debug(DEBUG_TYPE, 'UNMOUNTED *********************************** ')
      // 컴포넌트가 언마운트될 때 브리지 함수 해제 ------> 중요 *************************
      delete window.CallBack;
    };
  }, []);
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // confirmLottoData 변경되면 구매금액 재집계
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  useEffect(() => {
    debug(DEBUG_TYPE, "[ auditDone 변화 감지 ] auditData : ", auditData)
    if (auditLoading === false && auditDone === true) {
      debug(DEBUG_TYPE, "[ auditDone 변화 감지 ] setAudit() call /  : auditDone ", auditDone)
      const auditProceData = auditProc(auditData);
      setAudit(auditProceData);
    }
  }, [auditLoading, auditDone, auditData])

  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 하단에 알림 메세지 띄우기 
  // 메세지 내용은 setAlertMessage(메세지내용)으로 셋업
  // 메세지 띄우기 setOpenMsg(true), 당기 setOpenMsg(false);
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleMsgOpen = useCallback(() => {
    setOpenMsg(true);
  }, []);

  const handleMsgClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsg(false);
  }, []);
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // confirmLottoData: 로또 확인용 당첨번호 
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // useEffect(() => {
  //   debug(DEBUG_TYPE, '[ Home ] Store confirmLottoData 변경 event', confirmLottoData)
  //   setConfirmArrayData(confirmLottoData)
  // }, [confirmLottoData])
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 조회된 회차 구매내역이 존재하면 당첨번호 확인.
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleConfirmLottoOpen = async () => {
    debug(DEBUG_TYPE, "[ Home ] 당첨 확인 버튼 클릭 step 1 : 현재 선택된 weekNo ", weekNo);
    const confirmTitle = `${weekNo}회 구매전표`
    setTitle(confirmTitle);
    setConfirmType('WEEK');
    const keyName = 'weekNo';
    const key = weekNo + '';
    // return;
    debug(DEBUG_TYPE, '=======================================================');
    const savedData = await findData(LOTTO_MINE, keyName, key);
    const myData = await findData(LOTTO_MINE, 'weekNo', '9999');
    // 'myData' 배열의 'weekno' 값을 1091로 변경
    debug(DEBUG_TYPE, "[ Home ] 당첨 확인 버튼 클릭 step 2 : handleConfirmLottoOpen => finded data", savedData);
    debug(DEBUG_TYPE, "[ Home ] 당첨 확인 버튼 클릭 step 3 : handleConfirmLottoOpen => finded myData", myData);

    let confirmData = [];
    if (myData && myData.length > 0) {
      myData[0].datas.forEach(item => {
        item.weekno = weekNo;
      });
      confirmData = [...myData, ...savedData]
    } else {
      confirmData = [...savedData]
    }
    debug(DEBUG_TYPE, "[ Home ] 당첨 확인 버튼 클릭 step 4 : handleConfirmLottoOpen => confirmData", confirmData);
    const findTypeWeekno = {
      type: 'WEEK',
      weekNo: weekNo
    }
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
    });
    dispatch({
      type: CONFIRM_LOTTO_REQUEST,
      data: confirmData
    });

    setTimeout(() => {
      dispatch({
        type: CONFIRM_DIALOG_OPEN,
        data: true
      });
    }, 200); // 10초를 밀리초로 표현
    // dispatch({
    //   type: CONFIRM_DIALOG_OPEN,
    //   data: true
    // });
    debug(DEBUG_TYPE, `[ Home ]  당첨 확인 버튼 클릭 step 5 : ${weekNo} 회차 당첨확인완료 `, confirmLottoData);
    // setConfirmArrayData(confirmLottoData);
  };

  const handleConfirmLottoClose = useCallback(() => {
    dispatch({
      type: CONFIRM_DIALOG_OPEN,
      data: false
    });
  }, []);


  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // QR 스캔 및 스캔데이타 저장.
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleSave = useCallback(() => {
    debug(DEBUG_TYPE, '[ Home ] handleSave 호출, save Data ', confirmLottoData);
    const newConfirmArrayData = confirmLottoData.map(item => {
      if (item.datas[0].winyn === 'Y') {
        return {
          ...item
          , winYn: item.datas[0].winyn
          , weekDate: item.datas[0].weekdate
        };
      }
      return item;
    });
    debug(DEBUG_TYPE, '[ Home ] handleSave 호출, newConfirmArrayData ', newConfirmArrayData);
    // setConfirmArrayData(newConfirmArrayData);
    insertData(LOTTO_MINE, 'trCode', newConfirmArrayData);
    // debug(DEBUG_TYPE, '[ Home ] handleSave 호출, newYn 변경전 :  ', newYn);
    setNewYn('N');
    debug(DEBUG_TYPE, '[ Home ] handleSave 호출, newYn 변경전 :  ', 'N');

    auditFunc();
  }, [confirmLottoData]);

  const handleDelete = async () => {
    debug(DEBUG_TYPE, '[ Home ] handleDelte 호출, delete Data ', confirmLottoData);
    debug(DEBUG_TYPE, '[ Home ] handleDelte 호출, trcode :  ', confirmLottoData[0].trCode);
    await deleteData(LOTTO_MINE, confirmLottoData[0].trCode);
    // debug(DEBUG_TYPE, '[ Home ] handleDelte 호출, newYn 변경전 :  ', newYn);
    setNewYn('Y');
    // debug(DEBUG_TYPE, '[ Home ] handleDelte 호출, newYn 변경 후  :  ', 'Y');
    auditFunc();
  };
  // =======================================================================
  const handleQrOpen = () => {
    debug(DEBUG_TYPE, '[ Home ] QR 스캔버튼 클릭 ');
    setConfirmType('QR');
    cameraScan();  // 안드로이드에서 callBack 함수를 호출해준다. 
    // debug(DEBUG_TYPE, '[ Home ] QR 스캔버튼 data ', confirmQrScanData);
  }
  // ***********************************************
  //   안드리이드 웹뷰에서 브릿지를 통해 call 하는 전역 함수. 
  //   webView.loadUrl("javascript:view.CallBack('" + scan_data + "', 'QR' )");
  //
  //   javascript:view.CallBack(
  //    'http://m.dhlottery.co.kr/?v=1082q121422233336q030914273639q080913192026q113138414344n0000000000001425384017', 
  //    'QR' ) 
  // ***********************************************
  const CallBack = async (scanData, scanType) => {
    // alert('CallBack 호출')
    // debug(DEBUG_TYPE, '[ Home ] callBack 함수 호출', scanType, scanData);
    // const msg = `callBack 함수 호출 scanType=${scanType}- ${scanData}`
    setTitle('QR 스캔')
    // setAlertMessage(msg)
    const scanDataJson = dataParser(scanData);
    const scanWeekNo = scanDataJson.weekNo;
    const scanTrCode = scanDataJson.trCode;
    const savedData = await findData(LOTTO_MINE, 'key', scanTrCode);
    let newYnVal = 'N';

    if (savedData.length > 0) {
      scanDataJson.newYn = 'Y'
      newYnVal = 'N';
      setNewYn('N');
    } else {
      scanDataJson.newYn = 'Y'
      newYnVal = 'Y';
      setNewYn('Y');
    }
    const scanDataArray = []
    scanDataArray.push(scanDataJson)
    const findTypeWeekno = {
      type: 'QR',
      weekNo: scanWeekNo,
      newYn: newYnVal
    }
    // debug(DEBUG_TYPE, `[ Home ] callBack step 1 : ${findTypeWeekno.weekNo} 회차의 추첨일자 및 당첨번호 조회:`, findTypeWeekno);
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
      // home state : confirmWeekData = {} 자료 생성
      // Home화면에서 사용하는 week 정보 = weekData / 당첨팝업창에서 사용하는 week 정보 : confirmWeekData
    });
    // debug(DEBUG_TYPE, `[ Home ] callBack step 2 : ${findTypeWeekno.weekNo} 회차 QR Scan 전표 당첨확인 요청  :`, scanDataArray);
    dispatch({
      type: CONFIRM_LOTTO_REQUEST,
      data: scanDataArray
      // home state : confirmLottoData = [] 자료 생성
    });
    // debug(DEBUG_TYPE, `[ Home ] callBack step 2 : ${findTypeWeekno.weekNo} 회차 QR Scan 전표 당첨확인 응답  :`, confirmLottoData);
    setTimeout(() => {
      dispatch({
        type: CONFIRM_DIALOG_OPEN,
        data: true
      });
    }, 200); // 10초를 밀리초로 표현
    // debug(DEBUG_TYPE, `[ Home ] callBack step 2 : ${findTypeWeekno.weekNo} 회차 당첨확인 오픈  :`, confirmDialogOpened);
  }

  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 이전 회차 당첨번호 조회 
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleForward = () => {
    debug(DEBUG_TYPE, '[ Home ] handleForward ', weekNo + 1, lastWeekNo);
    const forwardWeekno = weekNo + 1
    if (forwardWeekno > lastWeekNo) {
      setAlertMessage('다음 회차는 존재하지 않습니다.')
      handleMsgOpen();
      return
    }
    const findTypeWeekno = {
      type: 'DIRECT',
      weekNo: forwardWeekno
    }
    setWeekNo(forwardWeekno)
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
    });
    debug(DEBUG_TYPE, ' [ Home ] handleForward weekno ', forwardWeekno)
  }
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 다음회차 당첨번호 조회 
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleBackward = () => {
    debug(DEBUG_TYPE, '[ Home ] handleBackward ');
    const backWardWeekno = weekNo - 1
    if (backWardWeekno <= 0) {
      setAlertMessage('이전 회차는 존재하지 않습니다.')
      handleMsgOpen();
      return
    }
    const findTypeWeekno = {
      type: 'DIRECT',
      weekNo: backWardWeekno
    }
    setWeekNo(backWardWeekno)
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
    });
    debug(DEBUG_TYPE, ' [ Home ] backWardWeekno weekno ', backWardWeekno)
  }
  // =============================================================================
  // css
  // =============================================================================
  const qrBtnStyle = useMemo(() => {
    return {
      backgroundColor: QR_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const confirmBtnStyle = useMemo(() => {
    return {
      backgroundColor: CONFIRM_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const appBarStyle = useMemo(() => {
    return {
      backgroundColor: APP_BAR_BACKGROUND_COLOR, // 원하는 텍스트 색상
    }
  }, []);
  // ------------------------------------------------------
  return (
    <>
      <CssBaseline />
      <AppBar position='relative' style={appBarStyle}>
        <Toolbar>
          <HomeIcon />
          <Typography variant='h6' style={{ paddingLeft: "10px" }}>
            너또 당첨
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Container maxWidth="md" style={{ marginTop: '10px' }}>
          <Paper elevation={3} style={{ padding: '10px' }}>

            <ResultContent
              history={true}
              weekData={weekData}
              handleForward={handleForward}
              handleBackward={handleBackward}
            />

            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              style={{ marginTop: '10px' }}
              fullWidth={true}
            >
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  style={qrBtnStyle}
                  startIcon={<QrCodeIcon />}
                  onClick={handleQrOpen}
                >QR스캔</Button>
                <Button
                  variant="contained"  // "contained"
                  style={confirmBtnStyle}
                  startIcon={<ContactSupportIcon />}
                  onClick={handleConfirmLottoOpen}
                // component={Link} to="/Home/WinConfirm/1111"
                >당첨확인</Button>
              </ThemeProvider>
            </ButtonGroup>

          </Paper>

        </Container>
        <Container maxWidth="md" style={{ marginTop: '10px' }}>
          <Paper elevation={3} style={{ padding: '10px', height: '100px' }}>
            {/* {alertMessage} */}
          </Paper>
        </Container>
        <Container maxWidth="md" style={{ marginTop: '10px' }}>
          <Paper elevation={3} style={{ padding: '10px' }}>
            <BuyResult audit={audit} />
          </Paper>
        </Container>
        {/* <Container maxWidth="md" style={{ marginTop: '10px' }}>
          <Paper elevation={3} style={{ padding: '10px', height: '100px' }}>

          </Paper>
        </Container> */}
      </main>

      <WeekWinConfirm
        open={confirmDialogOpened}
        title={title}
        handleClose={handleConfirmLottoClose}
        slipDatas={confirmLottoData} // 확인된 전표 
        weekData={confirmWeekData} // 회차정보 ( 일자 )
        confirmType={confirmType}  // QR , WEEK
        newYn={newYn}  // Y:신규, N:저장된데이타, R:갱신대상. 
        handleSave={handleSave}
        handleDelete={handleDelete}
        handleQrOpen={handleQrOpen}
        handleAuditFunc={auditFunc}
      />

      <MessageAlert
        openMessage={openMsg}
        handleClose={handleMsgClose}
        message={alertMessage}
      />
    </ >
  )
}

export default Home