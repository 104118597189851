import produce from '../utils/produce';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'reducers_anal'

export const initialState = {
  loading: true,
  //로또 추첨 번호 
  findHighToRowLoading: false, //
  findHighToRowDone: false,
  findHighToRowError: null,
  findHighToRowData: []
};
export const FIND_HIGH_TO_ROW_REQUEST = 'FIND_HIGH_TO_ROW_REQUEST';
export const FIND_HIGH_TO_ROW_SUCCESS = 'FIND_HIGH_TO_ROW_SUCCESS';
export const FIND_HIGH_TO_ROW_FAILUR = 'FIND_HIGH_TO_ROW_FAILUR';



/*
  1. component 에서 dispatch 호출 
    dispatch({
      type: LOG_IN_REQUEST,
    });
  2. reducer 자동 실행됨. 
  3. reducer 
*/
const reducer = (state = initialState, action) => produce(state, (draft) => {
  debug(DEBUG_TYPE, "[ anal reducer ] action.type : ", action.type, action.data);
  switch (action.type) {
    // ---------------------------------------
    // 회차번호로 추첨일자 및 당첨번호 조회. 
    // ---------------------------------------
    case FIND_HIGH_TO_ROW_REQUEST:
      draft.findHighToRowLoading = true;
      draft.findHighToRowDone = false;
      draft.findHighToRowError = null;
      break;
    case FIND_HIGH_TO_ROW_SUCCESS:
      draft.findHighToRowLoading = false;
      draft.findHighToRowDone = true;
      draft.findHighToRowError = null;
      draft.findHighToRowData = action.data;
      break;
    case FIND_HIGH_TO_ROW_FAILUR:
      draft.findHighToRowLoading = false;
      draft.findHighToRowError = action.error;
      break;
    default:
      break;
  }
});

export default reducer;
