import { all, delay, fork, put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import { debug } from 'utils/common';

import {
  REQUEST_QRSCAN_CONFIRM_REQUEST,
  REQUEST_QRSCAN_CONFIRM_SUCCESS,
  REQUEST_QRSCAN_CONFIRM_FAILUR,
  //
  REQUEST_FIXLOTTO_CONFIRM_REQUEST,
  REQUEST_FIXLOTTO_CONFIRM_SUCCESS,
  REQUEST_FIXLOTTO_CONFIRM_FAILUR,
  //
  REQUEST_CHOICE_CONFIRM_REQUEST,
  REQUEST_CHOICE_CONFIRM_SUCCESS,
  REQUEST_CHOICE_CONFIRM_FAILUR,
} from '../reducers/saveNum';
const DEBUG_TYPE = 'saga_saveNum';

//---------------------------------------------------------------------
// 저장된 고정 번호에서 당첨확인 
//---------------------------------------------------------------------
function confirmFixNumsApi(fixSlipDatas) {
  debug(DEBUG_TYPE, ' confirmFixNumsApi() axios 호출 ', fixSlipDatas)
  const responsDatas = axios.post('/api/lotto/mydata', fixSlipDatas);
  return responsDatas;
}

function* confirmFixNums(action) {
  // console.log('[ Home saga ] findLotto() 호출 ')
  try {
    const fixSlip = action.data;
    const fixSlipDatas = fixSlip.datas
    debug(DEBUG_TYPE, ' confirmFixNums fixSlip', fixSlip)
    debug(DEBUG_TYPE, ' confirmFixNums fixSlip[0].datas', fixSlip.datas)
    debug(DEBUG_TYPE, ' confirmFixNums() axios 호출 전 fixSlipDatas', fixSlipDatas)
    const result = yield call(confirmFixNumsApi, fixSlipDatas);
    const confirmFixSlipDatas = result.data;
    debug(DEBUG_TYPE, ' confirmFixNums() axios 호출 후 fixSlipDatas', confirmFixSlipDatas)
    const confirmFixSlip = {
      ...fixSlip,
      newYn: 'Y',
      winYn: 'Y',
      datas: confirmFixSlipDatas
    };
    debug(DEBUG_TYPE, ' confirmFixNums() axios 호출 후 confirmFixSlip', confirmFixSlip)
    // yield delay(1000);
    yield put({
      type: REQUEST_FIXLOTTO_CONFIRM_SUCCESS,
      data: confirmFixSlip
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: REQUEST_FIXLOTTO_CONFIRM_FAILUR,
      error: err.response.data,
    });
  }
}

//---------------------------------------------------------------------
// 고정 번호에서 qrscan
//---------------------------------------------------------------------
function confirmQRScanApi(QRScanData) {
  debug(DEBUG_TYPE, ' confirmLottoApi() axios 호출 ', QRScanData)
  const responsDatas = axios.post('/api/lotto/mydata', QRScanData);
  return responsDatas;
}

function* confirmQRScan(action) {
  // console.log('[ Home saga ] findLotto() 호출 ')
  try {
    const qrScanSlip = action.data;
    const confirmRqDatas = qrScanSlip.datas
    debug(DEBUG_TYPE, ' confirmQRScanApi() axios 호출 전 ', confirmRqDatas)
    const result = yield call(confirmQRScanApi, confirmRqDatas);
    const confirmedDatas = result.data;
    debug(DEBUG_TYPE, ' confirmQRScanApi() axios 호출 후 ', confirmedDatas)
    const confirmSlip = {
      ...qrScanSlip,
      newYn: 'Y',
      winYn: 'Y',
      datas: confirmedDatas
    };
    // yield delay(1000);
    yield put({
      type: REQUEST_QRSCAN_CONFIRM_SUCCESS,
      data: confirmSlip
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: REQUEST_QRSCAN_CONFIRM_FAILUR,
      error: err.response.data,
    });
  }
}

//---------------------------------------------------------------------
// 선택된 번호 확인 
//---------------------------------------------------------------------
function confirmChoiceNumApi(choiceNums) {
  debug(DEBUG_TYPE, ' confirmLottoApi() axios 호출 ', choiceNums)
  const responsDatas = axios.post('/api/lotto/mydata', choiceNums);
  return responsDatas;
}

function* confirmChoiceNum(action) {
  // console.log('[ Home saga ] findLotto() 호출 ')
  try {
    const choiceSlip = action.data;
    const choiceNums = choiceSlip.datas
    debug(DEBUG_TYPE, ' confirmQRScanApi() axios 호출 전 ', choiceNums)
    const result = yield call(confirmChoiceNumApi, choiceNums);
    const confirmedChoiceNums = result.data;
    debug(DEBUG_TYPE, ' confirmQRScanApi() axios 호출 후 ', confirmedChoiceNums)
    const confirmChoiceSlip = {
      ...choiceSlip,
      newYn: 'Y',
      winYn: 'Y',
      datas: confirmedChoiceNums
    };
    // yield delay(1000);
    yield put({
      type: REQUEST_CHOICE_CONFIRM_SUCCESS,
      data: confirmChoiceSlip
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: REQUEST_CHOICE_CONFIRM_FAILUR,
      error: err.response.data,
    });
  }
}

function* watchConfirmFixNums() {
  yield takeLatest(REQUEST_FIXLOTTO_CONFIRM_REQUEST, confirmFixNums);
}
function* watchConfirmQRScan() {
  yield takeLatest(REQUEST_QRSCAN_CONFIRM_REQUEST, confirmQRScan);
}
function* watchConfirmChoiceNum() {
  yield takeLatest(REQUEST_CHOICE_CONFIRM_REQUEST, confirmChoiceNum);
}
export default function* userSaga() {
  yield all([
    fork(watchConfirmFixNums),
    fork(watchConfirmQRScan),
    fork(watchConfirmChoiceNum),
  ]);
}; 