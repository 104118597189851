import produce from '../utils/produce';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'reducers_sample'

export const initialState = {
  sampleLoadin: false,
  sampleDone: false,
  sampleError: null,
  sampleData: 'sample'
};

export const PROC_SAMPLE_REQUEST = 'PROC_SAMPLE_REQUEST';
export const PROC_SAMPLE_SUCCESS = 'PROC_SAMPLE_SUCCESS';
export const PROC_SAMPLE_FAILUR = 'PROC_SAMPLE_FAILUR';



const dummyUser = (data) => ({
  ...data,
  nickname: '제로초',
  id: 1,
  Posts: [{ id: 1 }],
  Followings: [{ nickname: '부기초' }, { nickname: 'Chanho Lee' }, { nickname: 'neue zeal' }],
  Followers: [{ nickname: '부기초' }, { nickname: 'Chanho Lee' }, { nickname: 'neue zeal' }],
});

/*
  1. component 에서 dispatch 호출 
    dispatch({
      type: LOG_IN_REQUEST,
    });
  2. reducer 자동 실행됨. 
  3. reducer 
*/
const reducer = (state = initialState, action) => produce(state, (draft) => {
  debug(DEBUG_TYPE, "[ home reducer ] action.type : ", action.type, action.data);
  switch (action.type) {
    case PROC_SAMPLE_REQUEST:
      draft.sampleLoadin = true;
      draft.sampleDone = false;
      draft.sampleError = null;
      break;
    case PROC_SAMPLE_SUCCESS:
      draft.sampleLoadin = false;
      draft.sampleDone = true;
      draft.sampleError = null;
      draft.sampleData = action.data
      break;
    case PROC_SAMPLE_FAILUR:
      draft.sampleLoadin = false;
      draft.sampleError = action.error;
      break;
    default:
      break;
  }
});

export default reducer;
