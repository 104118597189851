import React from 'react'
import {
  Container,
  Paper,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ResultContent from 'components/lotto-result/ResultContent';
import { useParams } from 'react-router-dom';

/**
 * 사용하는 화면에서 Page로 오픈 로 사용됨. 
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const WeekWinConfirmPage = () => {
  const { weekNo } = useParams();
  return (
    <>
      <h1>Week No: {weekNo}</h1>;
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            // onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Sound
          </Typography>
          <Button
            autoFocus color="inherit"
          // onClick={handleClose}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" style={{ marginTop: '10px' }}>
        {/* <Paper elevation={3} style={{ padding: '10px' }}> */}
        <ResultContent history={false} />
        {/* </Paper> */}
      </Container>
    </>
  )
}

export default WeekWinConfirmPage;