import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DefaultLayout from 'components/layout/defaultLayout/DefaultLayout';
import Home from 'pages/Home';
import Anal from 'pages/Anal';
import HighToRow from 'pages/Anal/HighToRow';
import HighToRowGap from 'pages/Anal/HighToRowGap';
import MonthlyChart from 'pages/Anal/MonthlyChart';
import TreeMapChart from 'pages/Anal/TreeMapChart';
import VerticalBarChart from 'pages/Anal/VerticalBarChart';
import SaveNum from 'pages/SaveNum';
import Store from 'pages/Storelotto';
import Setting from 'pages/Setting';
import DBPage from 'pages/DbCRUD';
import WeekWinConfirmPage from 'components/lotto-result/WeekWinConfirmPage';
import Privacy from 'pages/Privacy';

const publicPath = process.env.NODE_ENV === 'development' ? '/' : process.env.REACT_APP_PUBLIC_PATH || '/';
;
const App = () => {
  useEffect(() => {
    console.log("******process.env.NODE_ENV  ----", process.env.NODE_ENV)
    // ******process.env.NODE_ENV  ---- development
  }, [])
  return (
    <Router>
      <Routes>
        <Route path={publicPath} element={<DefaultLayout />}>
          <Route index element={<Home />} />
          <Route path="SaveNum" element={<SaveNum />} /> 
          <Route path="Anal" element={<Anal />} />
          <Route path="Anal/HighToRow" element={<HighToRow />} />
          <Route path="Anal/HighToRowGap" element={<HighToRowGap />} />
          <Route path="Anal/MonthlyChart" element={<MonthlyChart />} />
          <Route path="Anal/TreeMapChart" element={<TreeMapChart />} />
          <Route path="Anal/VerticalBarChart" element={<VerticalBarChart />} />
          <Route path="Store" element={<Store />} />
          <Route path="Setting" element={<Setting />} />
          <Route path="DB" element={<DBPage />} />
          <Route path="Home/WinConfirm/:weekNo" element={<WeekWinConfirmPage />} />

        </Route>
      </Routes>
    </Router>
  );
};

export default App;
