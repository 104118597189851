import React, { useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
// import { styled } from '@mui/material/styles';
// import Grid from '@mui/material/Grid'; // Grid version 1/\
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Ball from './Ball';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'RESULT_DETAIL_BALLS'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// 조건에 따라 글자색을 설정할 컴포넌트를 만듭니다.
const ResultBalls = (props) => {
  const [Ball01, setBall01] = useState({});
  const [Ball02, setBall02] = useState({});
  const [Ball03, setBall03] = useState({});
  const [Ball04, setBall04] = useState({});
  const [Ball05, setBall05] = useState({});
  const [Ball06, setBall06] = useState({});
  const ballDatas = (datas) => {
    // ball01: { ballNum: '1', colorYn: 'Y', ballSize: 'sm' },
    // console.log('[ ResultDetailBalls ] datas.num1 ', datas.num1, datas.num1.substring(1))
    // console.log('[ ResultDetailBalls ] datas.num1 ', datas.num1, datas.num1.substring(0, 1))
    let ball01Props = {};
    ball01Props.ballNum = Number(datas.num1.substring(1)).toString(); // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball01Props.colorYn = (datas.num1.substring(0, 1) === '0' ? 'N' : 'Y');
    ball01Props.ballSize = 'sm';
    setBall01(ball01Props);
    //
    let ball02Props = {};
    ball02Props.ballNum = Number(datas.num2.substring(1)).toString(); // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball02Props.colorYn = (datas.num2.substring(0, 1) === '0' ? 'N' : 'Y');
    ball02Props.ballSize = 'sm';
    setBall02(ball02Props);
    //
    let ball03Props = {};
    ball03Props.ballNum = Number(datas.num3.substring(1)).toString(); // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball03Props.colorYn = (datas.num3.substring(0, 1) === '0' ? 'N' : 'Y');
    ball03Props.ballSize = 'sm';
    setBall03(ball03Props);
    //
    let ball04Props = {};
    ball04Props.ballNum = Number(datas.num4.substring(1)).toString(); // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball04Props.colorYn = (datas.num4.substring(0, 1) === '0' ? 'N' : 'Y');
    ball04Props.ballSize = 'sm';
    setBall04(ball04Props);
    //
    let ball05Props = {};
    ball05Props.ballNum = Number(datas.num5.substring(1)).toString(); // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball05Props.colorYn = (datas.num5.substring(0, 1) === '0' ? 'N' : 'Y');
    ball05Props.ballSize = 'sm';
    setBall05(ball05Props);
    //
    let ball06Props = {};
    ball06Props.ballNum = Number(datas.num6.substring(1)).toString(); // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball06Props.colorYn = (datas.num6.substring(0, 1) === '0' ? 'N' : 'Y');
    ball06Props.ballSize = 'sm';
    setBall06(ball06Props);
    // 기존 배열에 새로운 객체 추가
    // const newSlipBalls = [...slipBalls, balls]
    // setSlipBalls(newSlipBalls);

    // console.log('slipBalls ', slipBalls);
  }
  useEffect(() => {
    debug(DEBUG_TYPE, '[ ResultDetailBalls ] props : ', props)
    ballDatas(props.balls)
  }, [props]);


  const winCss = useMemo(() => {
    return {
      fontSize: '12px',
      width: '30px',   // 당첨확인(1등, 2등, 3등, 4등, 5등, 낙첨), 미추첨
      color: 'black'
    };
  }, [])
  const winedCss = useMemo(() => {
    return {
      fontSize: '12px',
      width: '30px',   // 당첨확인(1등, 2등, 3등, 4등, 5등, 낙첨), 미추첨
      fontWeight: 'bold',
      color: 'red'
    };
  }, [])
  const ballDivCss = useMemo(() => {
    return {
      paddingLeft: '0px'
    };
  }, [])
  const textDivCss = useMemo(() => {
    return {
      paddingTop: '3px',
      paddingRigth: '0px',
    };
  }, [])
  const resultCss = useMemo(() => {
    return {
      fontSize: '12px',
      // backgroundColor: '#777799',
    };
  }, [])

  return (
    <div >
      <Grid container spacing={2}  >
        <Grid  >
          {props.balls.winrank === '1등' ||
            props.balls.winrank === '2등' ||
            props.balls.winrank === '3등' ||
            props.balls.winrank === '4등' ||
            props.balls.winrank === '5등'
            ? <div style={winedCss}>{props.balls.winrank}</div>
            : <div style={winCss}>{props.balls.winrank}</div>
          }
        </Grid>
        <Grid xs="Auto" style={ballDivCss} >
          <Stack direction="row" spacing={0.25} >
            <Ball state={Ball01} ></Ball>
            <Ball state={Ball02} ></Ball>
            <Ball state={Ball03} ></Ball>
            <Ball state={Ball04} ></Ball>
            <Ball state={Ball05} ></Ball>
            <Ball state={Ball06} ></Ball>
          </Stack>
        </Grid>
        <Grid xs style={textDivCss} >
          <span style={resultCss}>{props.balls.history}</span>
        </Grid>
      </Grid>
    </div >
  );
};

export default ResultBalls;