import React, { useEffect, useState, useMemo, useCallback } from 'react';
import numeral from 'numeral';
import './BuyResult.css';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'BuyResult'

const BuyResult = (props) => {
  const numberFormat = useCallback((number) => {
    const formattedNumber = numeral(number).format('#,##0');
    return formattedNumber
  }, [])
  const [audit, setAudit] = useState([]);
  useEffect(() => {
    debug(DEBUG_TYPE, 'BuyResult Mounted...', props);
    setAudit(props.audit);
  }, [props])

  const numberCSS = useCallback((number) => {
    if (number >= 0) {
      return { color: 'red' }
    }
    return { color: 'blue' }
  }, [])

  // Data for the table
  const rows = [
    { id: 1, name: 'John', age: 25 },
    { id: 2, name: 'Jane', age: 30 },
    { id: 3, name: 'Tom', age: 35 },
    { id: 4, name: 'Emily', age: 28 },
    { id: 5, name: 'Mike', age: 32 },
    { id: 6, name: 'Sarah', age: 40 },
    { id: 7, name: 'David', age: 27 },
  ];


  return (
    <>
      {/* <table>
        <thead>
          <tr>
            <th>열 1</th>
            <th>열 2</th>
            <th>열 3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>행 1, 열 1</td>
            <td>행 1, 열 2</td>
            <td>행 1, 열 3</td>
          </tr>
          <tr>
            <td>행 2, 열 1</td>
            <td>행 2, 열 2</td>
            <td>행 2, 열 3</td>
          </tr>
        </tbody>
      </table>  */}
      <table width="100%">
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="60%" />
        </colgroup>
        <thead>
          <tr>
            <th>구분</th>
            <th>횟수</th>
            <th>총금액</th>
          </tr>
        </thead>
        <tbody>

          {
            audit.filter(item => item.rank !== '구매')
              .filter(item => item.rank !== '결산')
              .map((item, index) => (
                <tr key={item.rank} >
                  <td align="center"> {item.rank}</td>
                  <td align="end"> {numberFormat(item.count)} 번</td>
                  <td align="end"> {numberFormat(item.amt)} 원</td>
                </tr>
              ))
          }
          <tr>
            <td align="center" > 구매 </td>
            <td align="end" > {audit[5] ? numberFormat(audit[5].count) : 0} 번 </td>
            <td align="end" > {audit[5] ? numberFormat(audit[5].amt) : 0} 원 </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" align="center"> 손익결산 </td>
            <td align="end" style={numberCSS(audit[6] ? audit[6].amt : 0)} > {audit[6] ? numberFormat(audit[6].amt) : 0}  원 </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default BuyResult;
