import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Typography, AppBar, CssBaseline, Toolbar, } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useNavigate } from "react-router-dom";

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { APP_BAR_BACKGROUND_COLOR } from 'utils/constValue';

const Anal = () => {
  const navigate = useNavigate();

  const style = useMemo(() => {
    return { fontWeight: '900', color: 'red' };
  }, []);

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    switch (index) {
      case 0:
        navigate('/Anal/HighToRow');
        // navigate.push('/Anal/HighToRow');
        break;
      case 1:
        navigate('/Anal/HighToRowGap');
        break;
      // case 1:
      //   navigate('/Anal/TreeMapChart');
      //   break;
      case 2:
        navigate('/Anal/MonthlyChart');
        break;
      case 3:
        navigate('/Anal/VerticalBarChart');
        break;
      default:
        navigate('/Anal');
    }

  };

  const appBarStyle = useMemo(() => {
    return {
      backgroundColor: APP_BAR_BACKGROUND_COLOR, // 원하는 텍스트 색상
    }
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBar position='relative' style={appBarStyle}>
        <Toolbar>
          <QueryStatsIcon />
          <Typography variant='h6' style={{ paddingLeft: "10px" }}>
            번호분석
          </Typography>
        </Toolbar>
      </AppBar>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          <ListItemText primary="번호별 출현횟수" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <StackedLineChartIcon />

          </ListItemIcon>
          <ListItemText primary="전체기간vs조회기간 갭순위 " />
        </ListItemButton>
        {/* <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <AlignHorizontalLeftIcon />
          </ListItemIcon>
          <ListItemText primary="회차별 홀짝비율 " />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText primary="버티칼 축제" />
        </ListItemButton> */}
      </List>
    </>
  )
}

export default Anal