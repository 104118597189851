import React, { uesEffect, useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const MessageAlert = (props) => {
  const [openMessage, setOpenMessage] = useState();
  const [message, setMessage] = useState();
  const handleClose = props.handleClose;

  useEffect(() => {
    setOpenMessage(props.openMessage);
    setMessage(props.message);

  }, [props])
  return (
    <Snackbar open={openMessage} autoHideDuration={1000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default MessageAlert