import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Typography, AppBar, CssBaseline, Toolbar, IconButton, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StoreIcon from '@mui/icons-material/Store';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import { FIND_STORE_REQUEST } from 'reducers/storelotto';
import { debug } from 'utils/common';
import { deviceWhat } from 'utils/brige';
import clipboardCopy from 'clipboard-copy';

import MessageAlert from 'components/MessageAlert';
import moment from 'moment';

import { Map, MapMarker, CustomOverlayMap } from 'react-kakao-maps-sdk';
import 'components/store-lotto/StoreInfo.css'
import { APP_BAR_BACKGROUND_COLOR } from 'utils/constValue';

const DEBUG_TYPE = 'Storelotto';
const CURRENT_LOCATION = { lat: 37.578881, lng: 126.8931235 };


// width="100%" height={300}
const Store = () => {
  // message state
  const [openMsg, setOpenMsg] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const handleMsgClose = () => {
    setOpenMsg(false)
  }

  const [level, setLevel] = useState(3);
  const [position, setPosition] = useState(CURRENT_LOCATION); // 현재 위치를 저장할 상태
  const [center, setCenter] = useState(CURRENT_LOCATION);
  const [stores, setStores] = useState([]);
  const [isPanto, setIsPanto] = useState(true);  // 지도 위치 변경시 panto를 이용할지에 대해서 정의
  const [callTime, setCallTime] = useState('');
  const [callType, setCallType] = useState('Init');
  const [device, setDevice] = useState('');

  const dispatch = useDispatch();
  const mapRef = useRef();

  // Store 변수.
  const {
    findStoreData,
  } = useSelector(state => state.storelotto);  // Array Type



  const MapStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 56px - 56px )', // 상단 56px과 하단 56px을 제외한 영역
  };

  // **************************************************************************
  // 처음 로딩시 
  // ************************************************************************** 
  useEffect(() => {
    async function getDevice() {
      // You can await here
      const rtn = await deviceWhat();
      setDevice(rtn);
    }
    getDevice();
    setCallType(`Mounted`);
    const callTimeText = moment().format('LLL');
    setCallTime(callTimeText);
    // 브리지 함수를 전역으로 등록하여 웹뷰에서 호출할 수 있게 만듬 
    setLevel(4)
    const location = {
      latitude: 37.578881, longitude: 126.8931235
    }
    dispatch({
      type: FIND_STORE_REQUEST,
      data: location
    });
    //현재 위치 정보 : 성공시 successHandler, 실패시 errorHandler 함수가 실행된다
    window.appCallBackLoc = appCallBackLoc;
    appGetLocation();
    // setTimeout(() => {
    // appGetLocation();
    // }, 200); // 10초를 밀리초로 표현

    return () => {
      // 컴포넌트가 언마운트될 때 브리지 함수 해제 ------> 중요 *************************
      //         ---> 요청 : Android.getLocation() ( 안드로이드 네이티브 함수 호출 )
      //         ---> 응답 : 안드로이드 appCallMap(latitude, longitude)
      // delete window.appCallBackLoc;
    };
  }, []);

  // ************************************************************************** 
  // store-findStoreData 데이타가 변경되면 화면에 뿌려주기
  // ************************************************************************** 
  useEffect(() => {
    debug(DEBUG_TYPE, 'findStoreData 변화감지 ', findStoreData)
    // {
    //     "lottoStorePK": {
    //         "loc_code": 12,
    //         "seq": 7370
    //     },
    //     "storename": "드림공인중개사사무소",
    //     "telno": "02-2648-7722",
    //     "address": "서울 양천구 목동중앙북로16길 3 광진상가1층104",
    //     "latitude": 37.5460499008958,
    //     "longitude": 126.873886642688,
    //     "useyn": "Y",
    //     "win1cnt": null,
    //     "win2cnt": null,
    //     "address2": "서울 양천구 목동 526",
    //     "road_address": "",
    //     "etc3": null
    // }
    // 각 배열 요소를 객체로 변환하고, 새로운 배열에 추가
    const newStores = findStoreData.map(item => {
      return {
        // key: `${item.lottoStorePk.loc_code}-${item.lottoStorePk.seq}`,
        title: item.storename,    // 판매점 명
        latlng: { lat: item.latitude, lng: item.longitude, },   // 판매점 위치 : 위도, 경도 
        address: item.address,
        address2: item.address2,
        win1cnt: item.win1cnt,
        win2cnt: item.win2cnt,
        tel: item.telno,
        isOpen: false,
      };
    });
    setStores(newStores);
  }, [findStoreData])

  //===================================================================================
  //  요청 : appGetLocation ()
  //         ---> 요청 : Android.getLocation() ( 안드로이드 네이티브 함수 호출 )
  //         ---> 응답 : 안드로이드 appCallMap(latitude, longitude)
  //===================================================================================
  const appGetLocation = async () => {
    const device = await deviceWhat();
    // debug(DEBUG_TYPE, ' [ step 1 : appGetLocation() ] 현재 위치조회 ', `device 정보 = ${device}`);
    if (device === "android") {
      // navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
      try {
        // eslint-disable-next-line no-undef
        Android.getLocation();
      } catch (err) {

      }
    } else if (device === "ios") {
      navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    } else {
      navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    }
  }
  //----------------------------------------
  // 네비게이터의 위치정보 수신 성공시 
  const successHandler = (response) => {
    // 집(목동)   : latitude: 37.537355, longitude: 126.8732485,
    // 회사(상암)  : latitude: 37.578881, longitude: 126.8931235 
    // debug(DEBUG_TYPE, ' [ step 2 : successHandler ]현재 위치 정보획득 성공 ', response);
    const { latitude, longitude } = response.coords;
    window.appCallBackLoc(latitude, longitude);
  };
  const errorHandler = (error) => {
    console.log(error);
  };
  // 해
  const appCallBackLoc = async (latitude, longitude) => {
    debug(DEBUG_TYPE, ' [ step 3 : appCallMap(latitude, longitude) ]   호출 ', `위도:${latitude} , 경도:${longitude}`);
    const callType = 'CallBack';
    setCallType(callType);
    const device = await deviceWhat();
    setDevice(device);
    const callTimeText = moment().format('LLL');
    setCallTime(callTimeText);

    // alert("appCallMap 호출 ")
    const getCurLocation = { lat: latitude, lng: longitude }
    setPosition(getCurLocation);
    setCenter(getCurLocation);
    const location = {
      latitude: latitude, longitude: longitude
    }
    dispatch({
      type: FIND_STORE_REQUEST,
      data: location
    });
  }
  // **************************************************************************
  // 현재 위치로 이동 
  // **************************************************************************
  const moveCenter = () => {
    debug(DEBUG_TYPE, " [ moveCenter ] position : ", position)
    setCenter(position);
    const location = {
      latitude: position.lat, longitude: position.lng
    }
    dispatch({
      type: FIND_STORE_REQUEST,
      data: location
    });
  };
  // **************************************************************************
  // move event
  // **************************************************************************
  const moveMapHandler = () => {
    //지도에 표기된 판매점 초기화, 마커 초기화 --> key 중복이 발생하므로 .
    setStores([]);
    const map = mapRef.current;
    const latitue = map.getCenter().getLat()
    const longitude = map.getCenter().getLng()
    debug(DEBUG_TYPE, `[moveMapHandler] lat : ${latitue} lng : ${longitude}`);
    setCenter({
      lat: latitue,
      lng: longitude,
    })
    const location = {
      latitude: latitue, longitude: longitude
    }
    dispatch({
      type: FIND_STORE_REQUEST,
      data: location
    });

  };


  // **************************************************************************
  // marker 클릭 handler
  // **************************************************************************
  const handleMarkerClick = (index) => {
    debug(DEBUG_TYPE, `marker Clicked : store[${index}]`, stores[index]);
    // 기존에 열려있는 모든 창을 fals로 바꾸어 닫는다. 
    const updateStores = stores.map(item => ({
      ...item,
      isOpen: false
    }));
    //선택한 판매점의 정보만 오픈한다. 
    updateStores[index].isOpen = !updateStores[index].isOpen
    setStores(updateStores);
    //선택한 판매점의 위치정보를 맵의 중앙으로 설정한다. 
    setCenter(updateStores[index].latlng);

  }
  // **************************************************************************
  // 정보 화면  클릭 handler
  // **************************************************************************
  const handleCloseStoreInfo = (index) => {
    debug(DEBUG_TYPE, 'closeStoreInfo 호출 ', `index : ${index}`)
    const updateStores = [...stores]
    updateStores[index].isOpen = false;
    setStores(updateStores);
  }
  // **************************************************************************
  // 주소 복사 
  // **************************************************************************
  const handlecopyBtnClick = (address) => {
    debug(DEBUG_TYPE, 'handlecopyBtnClick, address : ', address)
    clipboardCopy(address); // 클립보드에 텍스트 복사
    setAlertMessage('주소를 복사하였습니다.')
    setOpenMsg(true);
  }
  const gpsStyle = useMemo(() => {
    return {
      position: 'absolute',
      color: '#22668D',
      top: '60px',
      fontSize: '40px',
      left: '5px',
      padding: '4px 8px',
      border: '1px solid #dbdbdb',
      backgroundColor: '#fff', // CSS 속성 이름을 backgroundColor로 수정
      borderRadius: '2px',
      boxShadow: '0 1px 1px rgba(0,0,0,.04)',
      // zIndex: 1,
      cursor: 'pointer',
      opacity: 0.8,
      zIndex: 9999, // 높은 숫자로 설정하여 가장 위로 보이도록 함
    }
  }, []);
  const appBarStyle = useMemo(() => {
    return {
      backgroundColor: APP_BAR_BACKGROUND_COLOR, // 원하는 텍스트 색상
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar position='relative' style={appBarStyle}>
        <Toolbar>
          <StoreIcon />
          <Typography variant='h6' style={{ marginLeft: '10px' }}>
            판매점
          </Typography>
        </Toolbar>
      </AppBar>
      <GpsFixedTwoToneIcon style={gpsStyle} onClick={moveCenter} />
      <Map
        center={center}
        style={MapStyle}
        isPanto={isPanto}
        level={level}
        ref={mapRef}
        onDragEnd={moveMapHandler}
      >
        <MapMarker // 지도 중앙에 마커를 생성합니다
          position={{
            lat: center.lat,
            lng: center.lng
          }}
        />
        {stores.map((store, index) => (
          <MapMarker
            key={`${store.title}-${store.latlng}-${index}`}
            position={store.latlng} // 마커를 표시할 위치
            image={{
              src: "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png", // 마커이미지의 주소입니다
              size: {
                width: 24,
                height: 35
              }, // 마커이미지의 크기입니다
            }}
            // title={store.title} // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
            onClick={() => handleMarkerClick(index)}
          >
            {store.isOpen && (
              <div className="wrap">
                <div className="info">
                  <div className="title"> {store.title}
                    <div className="close" onClick={() => handleCloseStoreInfo(index)} title="닫기"></div>
                  </div>
                  <div className="body">
                    <div className="img">
                      <img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/thumnail.png" width="73" height="70" />
                    </div>
                    <div className="desc">
                      <div className="ellipsis">{store.address}</div>
                      <div className="jibun ellipsis">(지번) {store.address2}</div>
                      <div className="jibun ellipsis">(전화번호) {store.tel}</div>
                      <div>
                        주소복사 <IconButton onClick={() => handlecopyBtnClick(store.address)}><ContentCopyIcon style={{ fontSize: "10px" }} /></IconButton>

                      </div>
                      {/* <div><a href="https://www.kakaocorp.com/main" target="_blank" className="link" rel="noreferrer">홈페이지</a></div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </MapMarker>
        ))}
        <CustomOverlayMap position={{ center }}>
          <div className='overlay'>Here!</div>
        </CustomOverlayMap>

        {/* <MapTypeControl position={"TOPRIGHT"} />
        <ZoomControl position={"RIGHT"} /> */}
      </Map>
      {/* 
      {center && (
        <div style={{ fontSize: "14px" }} >
          {callType}[{device}] : {callTime}<br />
          <span >위도 : {position.lat}</span>
          <br />
          <span>경도 : {position.lng}</span>
        </div >
      )}
      <Button variant="outlined" size="small" onClick={moveCenter}>
        Small
      </Button>
      */}
      {/* <button onClick={() => setLevel(level + 1)}>-</button>
      <button onClick={() => setLevel(level - 1)}>+</button> */}
      {/* <MapMarker position={{ lat: coordinates.center.lat, lng: coordinates.center.lng}} /> */}
      {/* <button onClick={getCenterPostion}>맵중앙 좌표 얻기</button>
      <button onClick={moveCenter}>핸재 위치로 이동</button> */}
      {/* <ZoomControl position={mapRef.maps.ControlPosition.TOPRIGHT} /> */}
      {/* <ZoomControl position={{ lat: 33.450879, lng: 126.56994 }} /> */}
      {/* <MapTypeControl position={mapRef.maps.ControlPosition.TOPRIGHT}/> */}
      {/* <MapTypeControl position={{ lat: 33.450879, lng: 126.56994 }} style={{ width: '100px' }} /> */}
      {/* <div div style={{ fontSize: "14px" }} >
        현재 위치의 좌표는..<br />
        <span >위도 : {position.lat}</span>
        <br />
        <span>경도 : {position.lng}</span>
      </div> */}
      <MessageAlert
        openMessage={openMsg}
        handleClose={handleMsgClose}
        message={alertMessage}
      />
    </>
  )
}
export default Store