import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';

import Ball from './Ball';

import { debug } from 'utils/common';
const DEBUG_TYPE = 'RESULT_BALLS';

const PlusIconCenter = styled.div`
  display: grid;
  place-items: center; /* 세로와 가로로 가운데 정렬 */
`;

const ResultDetailBalls = (props) => {
  const [Ball01, setBall01] = useState({});
  const [Ball02, setBall02] = useState({});
  const [Ball03, setBall03] = useState({});
  const [Ball04, setBall04] = useState({});
  const [Ball05, setBall05] = useState({});
  const [Ball06, setBall06] = useState({});
  const [BallBonus, setBallBonus] = useState({});

  const ballDatas = (datas) => {
    debug(DEBUG_TYPE, '[ ResultBalls ] ballDatas prameter datas ', datas)
    let ball01Props = {};
    ball01Props.ballNum = datas.num1; // 문자를 숫자로 변환 후 문자로 ( 01 -> 1 바꾸기 위해. )
    ball01Props.colorYn = 'Y';
    ball01Props.ballSize = 'md';
    setBall01(ball01Props);
    //
    let ball02Props = {};
    ball02Props.ballNum = datas.num2;
    ball02Props.colorYn = 'Y';
    ball02Props.ballSize = 'md';
    setBall02(ball02Props);
    //
    let ball03Props = {};
    ball03Props.ballNum = datas.num3;
    ball03Props.colorYn = 'Y';
    ball03Props.ballSize = 'md';
    setBall03(ball03Props);
    //
    let ball04Props = {};
    ball04Props.ballNum = datas.num4;
    ball04Props.colorYn = 'Y';
    ball04Props.ballSize = 'md';
    setBall04(ball04Props);
    //
    let ball05Props = {};
    ball05Props.ballNum = datas.num5;
    ball05Props.colorYn = 'Y';
    ball05Props.ballSize = 'md';
    setBall05(ball05Props);
    //
    let ball06Props = {};
    ball06Props.ballNum = datas.num6;
    ball06Props.colorYn = 'Y';
    ball06Props.ballSize = 'md';
    setBall06(ball06Props);

    let ballBnsProps = {};
    ballBnsProps.ballNum = datas.bonusnum;
    ballBnsProps.colorYn = 'Y';
    ballBnsProps.ballSize = 'md';
    setBallBonus(ballBnsProps);
    // 기존 배열에 새로운 객체 추가
    // const newSlipBalls = [...slipBalls, balls]
    // setSlipBalls(newSlipBalls);

    // console.log('slipBalls ', slipBalls);
  }

  useEffect(() => {
    debug(DEBUG_TYPE, '[ ResultBalls ] props ', props);
    ballDatas(props.weekData);
    // const balls = {
    //   ball01: { ballNum: '1', colorYn: 'Y' },
    //   ball02: { ballNum: '11', colorYn: 'Y' },
    //   ball03: { ballNum: '21', colorYn: 'Y' },
    //   ball04: { ballNum: '31', colorYn: 'Y' },
    //   ball05: { ballNum: '41', colorYn: 'Y' },
    //   ball06: { ballNum: '45', colorYn: 'Y' },
    //   ballBonus: { ballNum: '27', colorYn: 'Y' },
    // }
    // const ball01State = { ...balls.ball01, ballSize: 'md' }
    // setBall01(ball01State);
    // setBall02({ ...balls.ball02, ballSize: 'md' });
    // setBall03({ ...balls.ball03, ballSize: 'md' });
    // setBall04({ ...balls.ball04, ballSize: 'md' });
    // setBall05({ ...balls.ball05, ballSize: 'md' });
    // setBall06({ ...balls.ball06, ballSize: 'md' });
    // setBallBonus({ ...balls.ballBonus, ballSize: 'md' });
  }, [props]);
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }} >
          < Stack direction="row" spacing={0.5} align="center">
            <Ball state={Ball01} ></Ball>
            <Ball state={Ball02} ></Ball>
            <Ball state={Ball03} ></Ball>
            <Ball state={Ball04} ></Ball>
            <Ball state={Ball05} ></Ball>
            <Ball state={Ball06} ></Ball>
            <PlusIconCenter><AddIcon fontSize="xsmall" align="center" /></PlusIconCenter>
            <Ball state={BallBonus} ></Ball>
          </Stack>
        </Box>
      </div>
    </div>
  );
};

export default ResultDetailBalls;