import React, { memo, useEffect, useState } from 'react'
import { debug } from 'utils/common';

const DEBUG_TYPE = 'WEEK'

const data = {
  weekno: 1070,
  weekDate: '2023년 06월 03일 (토)'
}

const cssAlignCenter = memo(() => {
  return { display: 'flex', justifyContent: 'center', alignItems: 'center' };
}, []);


const WeekContent = (props) => {
  const [weekNo, setWeekNo] = useState(0);
  const [weekDate, setWeekDate] = useState('2');

  //props 자료 변화시 호출 
  useEffect(() => {
    debug(DEBUG_TYPE, '[ Week ] props ', props);
    setWeekNo(props.weekData.weekno);
    setWeekDate(props.weekData.weekdate);
  }, [props]);


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span>{weekNo}</span><span>회 당첨번호</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span>{weekDate}</span>
      </div>
    </div>

  )
}

export default WeekContent