import React from 'react'
import Typography from '@mui/material/Typography';

const Setting = () => {

  const bodyCss = {
    margin: '10px',
  };
  return (
    <>
      <div style={bodyCss}>
        <Typography variant="subtitle1" component="h2">
          너또당첨 앱( 이하 "앱"이라 함) 앱사용자님의 정보를 소중하게 생각하고 있습니다.
        </Typography>
        <br />
        <Typography variant="subtitle1" component="h2">
          1. 단말기 이용서비스
        </Typography>
        <ul>
          <li>
            <Typography variant="subtitle1" component="h2">
              위치정보 : 근처의 로또판매점을 조회하기 위해 현재위치를 조회하고 있습니다.
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" component="h2">
              저장공간 : 구매한 로또정보를 관리하기 위해 휴대폰에 저장하고 있습니다.
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" component="h2">
              카메라 : 구매한 로또를 QR 스캔하기 위해 카매라를 이용하고 있습니다.
            </Typography>
          </li>
        </ul>
        <br />
        <Typography variant="subtitle1" component="h2">
          앱을 통해 저장되는 모든 정보는 개인휴대폰에만 저장됩니다.
        </Typography>
      </div >
    </>
  )
}
export default Setting