import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup, Input, Box } from '@mui/material';
import styled from '@emotion/styled';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useInput from 'hooks/useInput';
import Ball from 'components/lotto-result/Ball';
import ResultFixDetailBalls from 'components/lotto-result/ResultFixDetailBalls';
import { findData, updateData, deleteData, insertData, LOTTO_MINE } from 'utils/dbCommon';
import { REQUEST_FIXLOTTO_CONFIRM_REQUEST, REQUEST_CHOICE_CONFIRM_REQUEST, SET_FIX_SLIP } from 'reducers/saveNum';
import { fixNums } from 'sampleData/jsonSample';
import { numberPad } from 'utils/lottoCommon';
import { debug } from 'utils/common';

import './LottoChoice.css';

const DEBUG_TYPE = "LottoChoice";
const FIX_SLIP_TRNO = '9999909999';
const INIT_RESULT = '번호 6개를 선택하시면 당첨결과 확인 가능합니다.'
const INIT_SAVE_DETAIL = '저장된 내역이 존재하지 않습니다. '

const choiceBall = styled.div`
  position: relative;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LottoChoice = () => {
  const dispatch = useDispatch();

  // const [text, onChangeText, setText] = useInput('');
  const [numState, setNumState] = useState([]);
  const [checkedCount, setCheckedCount] = useState(0);
  const [choiceBall, setChoiceBall] = useState([]);
  const [result, setResult] = useState('');
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  const [saveFixSlip, setSaveFixSlip] = useState({});   //저장된 고정번호 전표 
  const [saveFixLotto, setSaveFixLotto] = useState([]);  //저장된 고정번호 전표.datas


  // Store 변수.
  const {
    fixLottoLoading,
    fixLottoDone,
    fixLottoError,
    fixLottoData,
    // 선택된 번호 
    choiceLottoLoading,
    choiceLottoDone,
    choiceLottoError,
    choiceLottoData, // {} slip 형태
  } = useSelector(state => state.saveNum);  // Array Type

  // 45개 번호의 input tag 속성값을 가지는 배열 초기화 
  const inputIdsAndNames = Array.from({ length: 45 }, (_, index) => ({
    id: `check645num${index + 1}`,
    name: `check645num`,
    value: `${index + 1}`,
    checked: false,
    disabled: false,
  }));

  // 배열 변수 초기화
  const initChoiceBall = Array.from({ length: 6 }, (_, index) => ({
    ballNum: 0,
    colorYn: 'N',
    ballSize: 'lg',
  }));

  const initProc = async () => {
    debug(DEBUG_TYPE, 'initProc 호출 ...... ')
    const initFixSlip = {
      ...fixNums,  // 고정번호 sample json object
      datas: []
    }
    const keyName = 'key';
    const key = FIX_SLIP_TRNO;
    const saveFindSlips = await findData(LOTTO_MINE, keyName, key);  // 배열 타입 리턴
    debug(DEBUG_TYPE, 'initProc-저장된 고정번호 ', saveFindSlips)
    if (saveFindSlips && saveFindSlips.length > 0) {
      // saveFixSlip[0].newYn = 'N'
      const saveFixSlip = saveFindSlips[0] // 무조건 1개만 넘어오기 때문에.
      dispatch({
        type: REQUEST_FIXLOTTO_CONFIRM_REQUEST,
        data: saveFixSlip
      });
      // fixLottoData 의 변화 감지에서 해결.
      // setSaveFixSlip(saveFixSlip);
      // setSaveFixLotto(saveFixSlip.datas);
    } else {
      setSaveFixSlip(initFixSlip)
      setSaveFixLotto(initFixSlip.datas);
    }
  }
  useEffect(()=>{
    debug(DEBUG_TYPE, 'saveFixSlip 변화감지  ', saveFixSlip)
  }, [saveFixSlip]);
  useEffect(()=>{
    debug(DEBUG_TYPE, 'saveFixLotto 변화감지  ', saveFixLotto)
  }, [saveFixLotto]);
  // *+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // 해당 컴포넌트 mounted 시점에 실행 ( 가장먼저 실행. )
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    debug(DEBUG_TYPE, 'Mounted....................................................... ')
    setNumState(inputIdsAndNames);
    setChoiceBall(initChoiceBall);
    initProc();
  }, [])
  // -----------------------------------------------------------------
  // 저장된 고정번호를 당첨확인 후 해당 내역을 화면에 보여주기 위해
  // -----------------------------------------------------------------\
  useEffect(() => {
    debug(DEBUG_TYPE, 'fixLottoData 변화 감지 ', fixLottoData)
    const fixSlip = fixLottoData;
    const fixSlipDatas = fixLottoData.datas;
    if (fixSlipDatas && fixSlipDatas.length > 0) {
      setSaveFixSlip(fixSlip);
      setSaveFixLotto(fixSlipDatas);
    }
  }, [fixLottoData])
  // -----------------------------------------------------------------
  // 45개 숫자 state에 변화가 발생하면 선택된 갯수 계산하여 선택된갯수 state에 저장. 
  // -----------------------------------------------------------------\
  useEffect(() => {
    // debug(DEBUG_TYPE, 'numState 변화감지 ', numState)
    let checkedCnt = 0;
    let updateChoiceBall = [...initChoiceBall]
    for (let idx = 0; idx < 45; idx++) {
      if (numState[idx] && numState[idx].checked) {
        // debug(DEBUG_TYPE, 'numState 선택된 것 ', numState[idx], updateChoiceBall[idx])
        updateChoiceBall[checkedCnt].ballNum = numState[idx].value * 1;
        updateChoiceBall[checkedCnt].colorYn = 'Y';
        setChoiceBall(updateChoiceBall);
        // debug(DEBUG_TYPE, 'numState 선택된 것 ', updateChoiceBall)
        checkedCnt++;
      }
      if (checkedCnt >= 6) {
        setCheckedCount(checkedCnt);
        break;
      }
    }
    // 선택된것 이 없으면 모두 초기화 , 마지막 1개 남은것이 없어지지 않아서 꼭 넣어줌.
    if (checkedCnt === 0) setChoiceBall(initChoiceBall);
    // 6개 아닌경우 당첨내역 초기화
    if (checkedCnt !== 6) setResult(INIT_RESULT);
    // checkedCount State 변경 ----> checkedCount 변화감지로 후처리 --> 6개 선택시 당첨내역 조회
    setCheckedCount(checkedCnt);
  }, [numState])
  // -----------------------------------------------------------------
  // 선택된 숫자 수  변화감지 --> 6개 선택되면 더 선택 하지 못하도록 잠금
  // -----------------------------------------------------------------
  useEffect(() => {
    // debug(DEBUG_TYPE, ' checkedCount 변화감지 ', checkedCount);
    if (numState.length !== 45) return;
    if (checkedCount >= 6) {
      const updatedNumState = numState.map(item =>
        item.checked === false ? { ...item, disabled: true } : item
      );
      setNumState(updatedNumState);
      confirmChoiceProc();  // 선택된 번호 당첨 확인
    } else {
      const updatedNumState = numState.map(item =>
        item.checked === false ? { ...item, disabled: false } : item
      );
      setNumState(updatedNumState);
      setSaveBtnDisabled(true);
    }
  }, [checkedCount])
  // -----------------------------------------------------------------
  // 6개 선택된 되면 당첨 확인 하기 .
  // -----------------------------------------------------------------
  const confirmChoiceProc = () => {
    // debug(DEBUG_TYPE, '선택된번호 당첨 히스토리 확인 ', choiceBall)
    let choiceSlip = { ...fixNums } // sample json 
    const dataSample = fixNums.datas[0]
    const choiceDatas = {
      ...dataSample,
      num1: numberPad(choiceBall[0].ballNum, 3),
      num2: numberPad(choiceBall[1].ballNum, 3),
      num3: numberPad(choiceBall[2].ballNum, 3),
      num4: numberPad(choiceBall[3].ballNum, 3),
      num5: numberPad(choiceBall[4].ballNum, 3),
      num6: numberPad(choiceBall[5].ballNum, 3),
    }
    let datas = [];
    datas.push(choiceDatas)
    choiceSlip.datas = datas
    dispatch({
      type: REQUEST_CHOICE_CONFIRM_REQUEST,
      data: choiceSlip // choiceLottoLoading, choiceLottoDone, choiceLottoError, choiceLottoData
    });
    // 처리 후 choiceLottoDone의 변화감지로 후처리 
    // 선택된 번호가 저장된 것 인지 확인하여 저장된 데이터면, 저장버튼 비활성화. 
    // saveFixLotto 배열에 존재하면 setSaveBtnDisabled(true)
    let saveBtnDisabled = false;
    for (let idx = 0; idx < saveFixLotto.length; idx++) {
      const saveLotto = saveFixLotto[idx]; // str.substr(2);
      const num1 = saveLotto.num1.substr(1, 2) * 1;
      const num2 = saveLotto.num2.substr(1, 2) * 1;
      const num3 = saveLotto.num3.substr(1, 2) * 1;
      const num4 = saveLotto.num4.substr(1, 2) * 1;
      const num5 = saveLotto.num5.substr(1, 2) * 1;
      const num6 = saveLotto.num6.substr(1, 2) * 1;
      if (num1 === choiceBall[0].ballNum
        && num2 === choiceBall[1].ballNum
        && num3 === choiceBall[2].ballNum
        && num4 === choiceBall[3].ballNum
        && num5 === choiceBall[4].ballNum
        && num6 === choiceBall[5].ballNum) {
        const temp = [];
        temp.push(`${num1} vs ${choiceBall[0].ballNum}`);
        temp.push(`${num2} vs ${choiceBall[1].ballNum}`);
        temp.push(`${num3} vs ${choiceBall[2].ballNum}`);
        temp.push(`${num4} vs ${choiceBall[3].ballNum}`);
        temp.push(`${num5} vs ${choiceBall[4].ballNum}`);
        temp.push(`${num6} vs ${choiceBall[5].ballNum}`);
        debug(DEBUG_TYPE, '저장버튼 활성화 조건 검즌', temp);
        saveBtnDisabled = true
      }
    }
    setSaveBtnDisabled(saveBtnDisabled)
  }
  // -----------------------------------------------------------------
  // 선택된 번호 당첨내역 확인완료 되었는지 state 감지.
  // -----------------------------------------------------------------
  useEffect(() => {
    // debug(DEBUG_TYPE, '선택된번호 당첨확인 choiceLottoDone 변화감지 ', choiceLottoDone)
    // 당첨확인 
    if (choiceLottoDone === true) {
      const confirmResult = choiceLottoData.datas[0].history;
      setResult(confirmResult);
    }
    if (choiceLottoError !== null) {
      // 에러 내역 확인.
    }
  }, [choiceLottoDone, choiceLottoError]);
  // -----------------------------------------------------------------
  // 초기화 버튼 
  // -----------------------------------------------------------------
  const handleInitBtn = () => {
    // debug(DEBUG_TYPE, '초기화 버튼 클릭');
    const updatedNumState = numState.map(item => ({
      ...item,
      checked: false,
      disabled: false
    }));
    setNumState(updatedNumState);
    setChoiceBall(initChoiceBall);
  }
  // -----------------------------------------------------------------
  // 자동 버튼 
  // -----------------------------------------------------------------
  const handleAutoBtn = () => {
    debug(DEBUG_TYPE, '자동버튼 클릭');
  }
  // -----------------------------------------------------------------
  // 저장 버튼 
  // -----------------------------------------------------------------
  const handleSaveBtn = async () => {
    debug(DEBUG_TYPE, '저장버튼 클릭');
    if (saveFixLotto && saveFixLotto.length > 0) {
      // 갱신
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 ', fixLottoData)
      const choiceData = choiceLottoData.datas[0];
      const savedDatas = fixLottoData.datas
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 선택된 번호', choiceData)  // 선택된 번호 {}
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 저장된 번호들', savedDatas) // 저장딘 번호들 []
      const updateDatas = [...savedDatas, choiceData]
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 선택된 번호 추가한 저장할 번호들', updateDatas) // 저장딘 번호들 []
      // 배열 자료 trseq , trseqmax 값 조정. 
      const sortUpdateDatas = updateDatas.map((item, index) => ({
        ...item,
        seq: index + 1,
        trseq: index + 1,
        trseqmax: updateDatas.length
      }));

      const updateFixLottoSlip = {
        ...fixLottoData,
        datas: sortUpdateDatas
      }
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 저장할 고정번호 전표 ', updateFixLottoSlip)
      await updateData(LOTTO_MINE, FIX_SLIP_TRNO, updateFixLottoSlip);
    } else {
      // 신규 저장.
      // debug(DEBUG_TYPE, '저장버튼 - 신규저장 choiceLottoData ', choiceLottoData)
      const newFixSlip = [];
      newFixSlip.push(choiceLottoData);
      await insertData(LOTTO_MINE, 'trCode', newFixSlip);
    }
    setSaveBtnDisabled(true);
    handleInitBtn()
    initProc();
  }
  // -----------------------------------------------------------------
  // 전표 내역 삭제
  // -----------------------------------------------------------------
  const handleDeleteData = async (seq) => {
    debug(DEBUG_TYPE, '고정번호 삭제(handleDeleteData) 호출 ', seq);
    // 저장된 전표 조회 
    const keyName = 'key';
    const key = FIX_SLIP_TRNO;
    const findSlips = await findData(LOTTO_MINE, keyName, key);  // 배열 타입 리턴 []
    debug(DEBUG_TYPE, '고정번호 삭제(handleDeleteData)- 저장된 고정전표 조회 findSlips ', findSlips)
    const fixSlip = findSlips[0]
    // 고정번호 전표에 저장된 번호들 .
    const fixSlipDatas = fixSlip.datas
    debug(DEBUG_TYPE, '고정번호 삭제(handleDeleteData) 고정전표내 번호들 ', fixSlipDatas);
    const updateFixSlipDatas = fixSlipDatas.filter((item) => item.seq !== seq);
    debug(DEBUG_TYPE, `고정번호 삭제(handleDeleteData) 번호들중 seq = ${seq} 제거 `, updateFixSlipDatas);
    if (updateFixSlipDatas.length > 0) {
      //배열에서 seq 재설정
      const sortFixSlipDatas = updateFixSlipDatas.map((item, index) => ({
        ...item,
        seq: index + 1,
        trseq: index + 1,
        trseqmax: updateFixSlipDatas.length
      }));
      const updateFixSlip = {
        ...fixLottoData,
        datas: sortFixSlipDatas
      }
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 저장할 고정번호 전표 ', sortUpdateDatas)
      // debug(DEBUG_TYPE, '저장버튼 - 추가저장 slip 전 저장할 고정번호 전표 ', updateFixLottoSlip)
      await updateData(LOTTO_MINE, FIX_SLIP_TRNO, updateFixSlip);
    } else {
      // 저장할 데이타가 없으면 고정번호 전표 삭제 : deleteData(STORE_NAME, TR_CODE)
      await deleteData(LOTTO_MINE, FIX_SLIP_TRNO);
    }
    initProc();
  }
  // -----------------------------------------------------------------
  // inputbox 체크 클릭시 
  // 실행순서 : handleClick() ----> handleChange()
  // -----------------------------------------------------------------
  const handleClick = (event) => {
    // debug(DEBUG_TYPE, ' handleClick 클릭', event.target.value) 
    const clickedValue = event.target.value;
    // num 값이 2인 요소를 찾아 check 값을 true로 업데이트
    const updatedNumState = numState.map(item =>
      item.value === clickedValue ? { ...item, checked: !item.checked } : item
    );
    setNumState(updatedNumState);
  }
  // -----------------------------------------------------------------
  // inputbox 체크 클릭시 값이 변하면 처리되어야 할 로직,
  // STATE 변화감지로 변환 처리
  // -----------------------------------------------------------------
  const handleChange = () => {
    // debug(DEBUG_TYPE, ' handleChange 호출 ', numState); 
  }
  // -----------------------------------------------------------------
  // 값이 변하면 처리되어야 할 로직, STATE 변화감지로 변환 처리
  // -----------------------------------------------------------------
  const [isMoving, setIsMoving] = useState(false);
  const ball = {
    ballNum: 17,
    colorYn: 'Y',
    ballSize: 'lg'
  }
  const startAnimation = () => {
    setIsMoving(true);
    setTimeout(() => {
      setIsMoving(false);
    }, 2000); // 이동이 2초 동안 유지되도록 설정
  };
  // -----------------------------------------------------------------
  // CSS
  // -----------------------------------------------------------------
  const choiceBallCss = useMemo(() => {
    return { justifyContent: 'center', paddingTop: '10px' }
  }, []);

  const ArrowCss = useMemo(() => {
    return { fontSize: '36px', color: 'green' };
  }, []);

  return (
    <>
      <div className="content nums">
        <div id="checkNumGroup" className="select-number">
          <input type="hidden" id="activeGbn" name="activeGbn" />
          {numState.map((inputData, index) => (
            <React.Fragment key={index}>
              < input
                type="checkbox"
                id={inputData.id}
                name={inputData.name}
                value={inputData.value}
                checked={inputData.checked}
                disabled={inputData.disabled}
                onChange={handleChange}
                onClick={handleClick}
              />
              <label htmlFor={inputData.id}>{inputData.value}</label>
            </React.Fragment>
          ))}
        </div>
      </div >
      <div className="action">
        <ButtonGroup
          className="action"
          variant="contained"
          aria-label="outlined primary button group"
          fullWidth={true} >
          <Button color="secondary" onClick={handleInitBtn}>초기화</Button>
          <Button variant="contained" color="success" onClick={handleAutoBtn}>
            자동선택
          </Button>
          <Button onClick={handleSaveBtn} disabled={saveBtnDisabled}>
            저장
          </Button>
        </ButtonGroup>
      </div>
      <div >
        <Stack direction="row" spacing={1} style={{ justifyContent: 'center', paddingTop: '10px' }}>
          {choiceBall.map((ballState, index) => (
            <Ball key={index} state={ballState} ></Ball>
          ))}
        </Stack>
        <Stack direction="row" spacing={1} style={{ justifyContent: 'center', paddingTop: '10px' }}>
          <span >{result}</span>
        </Stack>
      </div>
      <div style={{ paddingTop: "20px" }}>
        <Divider />
        <span> 내 행운번호</span>
        <Divider style={{ marginBottom: "10px" }} />
        {saveFixLotto.map((fixLotto, index) => (
          <div key={`${fixLotto.trcode}-${fixLotto.seq}`} >
            <ResultFixDetailBalls balls={fixLotto} handleDeleteData={handleDeleteData} />
          </div>
        ))}
        {saveFixLotto.length === 0 ? INIT_SAVE_DETAIL : null}
        {/* <div style={{ paddingTop: "10px" }}>
          <ResultFixDetailBalls balls={balls} style={{ paddingTop: "20px" }} />
        </div> */}
        {/* <div style={{ paddingTop: "10px" }}>
          <ResultFixDetailBalls balls={balls} style={{ paddingTop: "20px" }} />
        </div> */}
      </div>
    </>
  )
}

export default LottoChoice