import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  // FIND_LOTTO_REQUEST,
  // FIND_LOTTO_SUCCESS,
  // FIND_LOTTO_FAILUR,
  //
  FIND_CONFIRM_WEEK_REQUEST,
  FIND_CONFIRM_WEEK_SUCCESS,
  FIND_CONFIRM_WEEK_FAILUR,
  //
  CONFIRM_LOTTO_REQUEST,
  CONFIRM_LOTTO_FAILUR,
  CONFIRM_LOTTO_SUCCESS,
  //
  FIND_WEEK_REQUEST,
  FIND_WEEK_SUCCESS,
  FIND_WEEK_FAILUR,
  //
  CONFIRM_AUDIT_REQUEST,
  CONFIRM_AUDIT_SUCCESS,
  CONFIRM_AUDIT_FAILUR,
} from '../reducers/home';
import { weekNoToDate } from 'utils/lottoCommon';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'saga_home_';

/*
function findLottoApi() {
  // console.log('[ Home saga ] findLottoApi() 호출 ')
  return axios.post('/api/signUp');
}

function* findLotto() {
  // console.log('[ Home saga ] findLotto() 호출 ')
  try {
    // const result = yield call(signUpAPI);
    yield delay(1000);
    yield put({
      type: FIND_LOTTO_SUCCESS,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: FIND_LOTTO_FAILUR,
      error: err.response.data,
    });
  }
}
*/

function confirmLottoApi(weekData) {
  debug(DEBUG_TYPE, '[ home saga ] confirmLottoApi() axios 호출 ', weekData)
  const responsDatas = axios.post('/api/lotto/mydata', weekData);
  return responsDatas;
}

function* confirmLotto(action) {
  // debug(DEBUG_TYPE, ' [ home saga ] confirmLotto() ===========================================');
  debug(DEBUG_TYPE, ' [ home saga ] confirmLotto() 호출 ', action.data);
  try {
    const weekConfirmLottoDatas = action.data;  // 특정주의 저장된 로또와 고정된 로또를 모두 가져와서 확인한다. 

    // weekData = [ { trCode, weekNo, weekDate, workDate, winYn, datas[] }, {trCode, weekNo, weekDate, workDate, winYn, datas[]}  ]
    // 상기 배열에서 datas 배열만 뽑아서 합치기. 
    const combinedArray = weekConfirmLottoDatas.map(item => item.datas).reduce((acc, curr) => acc.concat(curr), []);

    // 데이타를 합치 배열의 결과를 api를 호출하여 당첨결과 확인하여 리턴 받음. 
    // debug(DEBUG_TYPE, '****** [ home sage] confirmLotto Confrim 확인 Start', combinedArray);
    const result = yield call(confirmLottoApi, combinedArray);  // return type = Array
    // debug(DEBUG_TYPE, '****** [ home sage] confirmLotto Confrim 확인 End ', result);

    // datas 배열을 key 값에 따라 객체로 그룹화
    const groupedDatas = result.data.reduce((acc, curr) => {
      const trcode = curr.trcode;
      if (!acc[trcode]) {
        acc[trcode] = [];
      }
      acc[trcode].push(curr);
      return acc;
    }, {});

    // debug(DEBUG_TYPE, '****** [ home sage] groupedDatas ', groupedDatas);
    // debug(DEBUG_TYPE, '****** [ home sage] weekConfirmLottoDatas ', weekConfirmLottoDatas);
    // array 배열의 요소들 중에서 key 값에 따라 datas를 새롭게 대체
    const newArray = weekConfirmLottoDatas.map(item => ({
      ...item,
      // newYn: 'N',
      datas: groupedDatas[item.trCode]
    }));
    // debug(DEBUG_TYPE, '****** [ home sage] CONFIRM_LOTTO_SUCCESS dispatch ', newArray);

    // yield delay(1000);
    yield put({
      type: CONFIRM_LOTTO_SUCCESS,
      data: newArray
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: CONFIRM_LOTTO_FAILUR,
      error: err.response.data,
    });
  }
  debug(DEBUG_TYPE, ' [ home saga ] confirmLotto() ===========================================');
}

// -------------------------------------------------------------------
// confirmQrScan 은 trCode 로 사용하고 
// confirmLotto 는 weekNo 로 사용하기 때문데 다르게 갈 수 밖에 없다. 
// -------------------------------------------------------------------
/*
function confirmQrScanApi(scanData) {
  debug(DEBUG_TYPE, '[ home saga ] confirmQrScanApi() axios 호출 ', scanData)
  const responsDatas = axios.post('/api/lotto/mydata', scanData);
  return responsDatas;
}
function* confirmQrScan(action) {
  debug(DEBUG_TYPE, ' [ home saga ] confirmQrScan() 호출 ', action.data);
  try {
    const qrScanData = action.data
    const confirmDatas = qrScanData.datas;
    // -------------------------------------------------------
    const resultDatas = yield call(confirmQrScanApi, confirmDatas);  // return type = Array
    // 확인 후 전달 할 데이타 정리 .
    qrScanData.datas = resultDatas.data;
    // debug(DEBUG_TYPE, ' [ home saga ] confirmQrScan() 확인 후 데이타. ', resultDatas, qrScanData);
    qrScanData.winYn = resultDatas.data[0].winyn;
    qrScanData.weekDate = resultDatas.data[0].weekdate;
    // -----------------------------------------------------
    yield put({
      type: CONFIRM_QRSCAN_SUCCESS,
      data: qrScanData
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: CONFIRM_QRSCAN_FAILUR,
      error: err.response.data,
    });
  }
}
*/

// 회차 정보 가져오기. 
function findWeekApi(weekNo) {
  debug(DEBUG_TYPE, '[ home saga ] findWeekApi() axios 호출 ', weekNo)
  const url = '/api/lotto/data/' + weekNo;
  const responsDatas = axios.get(url);
  return responsDatas;
}

function* findWeek(action) {
  debug(DEBUG_TYPE, '[ home saga ] findWeek() 호출 ', action.data)
  // action.data = { type : 'QR' or 'DIRECT', weekNo : weekno }
  const paramObj = action.data;
  try {
    const result = yield call(findWeekApi, paramObj.weekNo);  // return type = {}
    debug(DEBUG_TYPE, '[ home saga ] findWeek() api 호출 후', result)
    if (result.data.weekno === null) {
      result.data.winyn = 'N';
    } else {
      result.data.winyn = 'Y';
    }
    result.data.weekno = paramObj.weekNo;
    const dateStr = weekNoToDate(paramObj.weekNo);
    result.data.weekdate = dateStr;

    if (paramObj.type === 'DIRECT' || paramObj.type === 'DETAIL') {
      // Home 화면에서 사용하는 week 정보 ( Prev Next 버튼 클릭 )
      debug(DEBUG_TYPE, '[ home saga ] findWeek() ---> 종료 ', paramObj.type)
      yield put({
        type: FIND_WEEK_SUCCESS,
        data: result.data
        // 변화 state : weekData 
      });
    } else {
      debug(DEBUG_TYPE, '[ home saga ] findWeek() ---> 자료 confirm 재 확인 ', paramObj.type)
      // QR Scan 및 당첨확인 팝업창에서 사용하는 week 정보 
      yield put({
        type: FIND_CONFIRM_WEEK_SUCCESS,
        data: result.data
        // 변화 state : confirmWeekData
      });
    }
  } catch (err) {
    console.error(err);
    yield put({
      type: FIND_WEEK_FAILUR,
      error: err.response.data,
    });
  }
}
// confirm 확인시 회차 정보 가져오기. 
function findConfirmWeekApi(weekNo) {
  debug(DEBUG_TYPE, '[ home saga ] findWeekApi() axios 호출 ', weekNo)
  const url = '/api/lotto/data/' + weekNo;
  const responsDatas = axios.get(url);
  return responsDatas;
}
function* findConfirmWeek(action) {
  debug(DEBUG_TYPE, '[ home saga ] findConfirmWeek() 호출 ', action.data)
  try {
    const result = yield call(findConfirmWeekApi, action.data);  // return type = {}
    yield put({
      type: FIND_CONFIRM_WEEK_SUCCESS,
      data: result.data
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: FIND_CONFIRM_WEEK_FAILUR,
      error: err.response.data,
    });
  }
}
// ---------------------------------------------------------------
function confirmAuditApi(auditData) {
  debug(DEBUG_TYPE, '[ home saga ] confirmAuditApi() axios 호출 ', auditData)
  const responsDatas = axios.post('/api/lotto/mydata', auditData);
  return responsDatas;
}
function* confirmAudit(action) {
  debug(DEBUG_TYPE, '[ home saga ] confirmAudit() 호출 ', action.data)
  try {
    const auditData = action.data;

    // 상기 배열에서 datas 배열만 뽑아서 합치기. 
    const combinedArray = auditData.map(item => item.datas).reduce((acc, curr) => acc.concat(curr), []);
    // 데이타를 합치 배열의 결과를 api를 호출하여 당첨결과 확인하여 리턴 받음. 
    const result = yield call(confirmLottoApi, combinedArray);  // return type = Array
    // datas 배열을 key 값에 따라 객체로 그룹화
    const groupedDatas = result.data.reduce((acc, curr) => {
      const trcode = curr.trcode;
      if (!acc[trcode]) {
        acc[trcode] = [];
      }
      acc[trcode].push(curr);
      return acc;
    }, {});
    // 기존 배열에 추가하기. 
    const confirmedAuditData = auditData.map(item => ({
      ...item,
      // newYn: 'N',
      datas: groupedDatas[item.trCode]
    }));
    yield put({
      type: CONFIRM_AUDIT_SUCCESS,
      data: confirmedAuditData
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: CONFIRM_AUDIT_FAILUR,
      error: err.response.data,
    });
  }
}
// ---------------------------------------------------------------

// function* watchFindLotto() {
//   yield takeLatest(FIND_LOTTO_REQUEST, findLotto);
// }

function* watchConfirmLotto() {
  yield takeLatest(CONFIRM_LOTTO_REQUEST, confirmLotto);
}



function* watchFindWeek() {
  yield takeLatest(FIND_WEEK_REQUEST, findWeek);
}

function* watchFindConfirmWeek() {
  yield takeLatest(FIND_CONFIRM_WEEK_REQUEST, findConfirmWeek);
}

function* watchConfirmAudit() {
  yield takeLatest(CONFIRM_AUDIT_REQUEST, confirmAudit);
}
/*

*/
export default function* userSaga() {
  yield all([
    // fork(watchFindLotto),
    fork(watchConfirmLotto),
    fork(watchFindWeek),
    fork(watchFindConfirmWeek),
    fork(watchConfirmAudit),
  ]);
}
