import produce from '../utils/produce';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'reducers_store_'

export const initialState = {
  findStoreLoadin: false,
  findStoreDone: false,
  findStoreError: null,
  findStoreData: []
};

export const FIND_STORE_REQUEST = 'FIND_STORE_REQUEST';
export const FIND_STORE_SUCCESS = 'FIND_STORE_SUCCESS';
export const FIND_STORE_FAILUR = 'FIND_STORE_FAILUR';

/*
  1. component 에서 dispatch 호출 
    dispatch({
      type: LOG_IN_REQUEST,
    });
  2. reducer 자동 실행됨. 
  3. reducer 
*/
const reducer = (state = initialState, action) => produce(state, (draft) => {
  debug(DEBUG_TYPE, "[ reducer ] action.type : ", action.type, action.data);
  switch (action.type) {
    case FIND_STORE_REQUEST:
      draft.findStoreLoadin = true;
      draft.findStoreDone = false;
      draft.findStoreError = null;
      break;
    case FIND_STORE_SUCCESS:
      draft.findStoreLoadin = false;
      draft.findStoreDone = true;
      draft.findStoreError = null;
      draft.findStoreData = action.data
      break;
    case FIND_STORE_FAILUR:
      draft.findStoreLoadin = false;
      draft.findStoreError = action.error;
      break;
    default:
      break;
  }
});

export default reducer;
