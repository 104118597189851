export const slipDatas = [
  {
    "trCode": "000000", "weekNo": "0001", "workDate": "0001-200021207", "weekDate": "", "winYn": "N",
    "datas": [
      {
        "trcode": "000000", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "010", "num2": "023", "num3": "029", "num4": "033", "num5": "037", "num6": "040"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "1", "rwin2": "0", "rwin3": "0", "rwin4": "1", "rwin5": "30"
        , "trseq": 1, "trseqmax": 5, "workdate": "0001-200021207"
      }
      , {
        "trcode": "000000", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "010", "num2": "016", "num3": "029", "num4": "033", "num5": "037", "num6": "040"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "1", "rwin3": "0", "rwin4": "1", "rwin5": "32"
        , "trseq": 2, "trseqmax": 5, "workdate": "0001-200021207"
      }
      , {
        "trcode": "000000", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "010", "num2": "023", "num3": "029", "num4": "033", "num5": "037", "num6": "045"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "0", "rwin3": "1", "rwin4": "0", "rwin5": "28"
        , "trseq": 3, "trseqmax": 5, "workdate": "0001-200021207"
      }
      , {
        "trcode": "000000", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "010", "num2": "023", "num3": "029", "num4": "033", "num5": "038", "num6": "045"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "0", "rwin3": "0", "rwin4": "3", "rwin5": "24"
        , "trseq": 4, "trseqmax": 5, "workdate": "0001-200021207"
      }
      , {
        "trcode": "000000", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "010", "num2": "023", "num3": "029", "num4": "032", "num5": "038", "num6": "045"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "0", "rwin3": "0", "rwin4": "1", "rwin5": "20"
        , "trseq": 5, "trseqmax": 5, "workdate": "0001-200021207"
      }]
  },
  {
    "trCode": "123456", "weekNo": "0001", "workDate": "0001-200021207", "weekDate": "", "winYn": "N",
    "datas": [
      {
        "trcode": "123456", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "007", "num2": "009", "num3": "012", "num4": "024", "num5": "037", "num6": "045"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "0", "rwin3": "0", "rwin4": "1", "rwin5": "33"
        , "trseq": 1, "trseqmax": 5, "workdate": "0001-200021207"
      }
      , {
        "trcode": "123456", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "007", "num2": "009", "num3": "012", "num4": "024", "num5": "037", "num6": "045"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "0", "rwin3": "0", "rwin4": "1", "rwin5": "33"
        , "trseq": 2, "trseqmax": 5, "workdate": "0001-200021207"
      }
      , {
        "trcode": "123456", "weekno": "0001", "weekdate": "2002년 12월 07일 (토)", "seq": 1, "type": "m"
        , "num1": "011", "num2": "021", "num3": "029", "num4": "031", "num5": "041", "num6": "044"
        , "winamt": 0, "winrank": "", "history": ""
        , "rweekno": "0000", "rwin1": "0", "rwin2": "0", "rwin3": "0", "rwin4": "2", "rwin5": "29"
        , "trseq": 3, "trseqmax": 5, "workdate": "0001-200021207"
      }
    ]
  }
];
export const slipDatas2 = [
  {
    "grantkey": 1234,
    "trcode": "9999999999",
    "weekno": "1075",
    "weekdate": "2023-04-29",
    "winyn": "N",
    "type": "N",
    "num1": "003",
    "num2": "008",
    "num3": "019",
    "num4": "023",
    "num5": "032",
    "num6": "045",
    "winrank": "고정"
  },
  {
    "grantkey": 1234,
    "trcode": "9999999999",
    "weekno": "1075",
    "weekdate": "2023-04-29",
    "winyn": "N",
    "type": "N",
    "num1": "007",
    "num2": "009",
    "num3": "012",
    "num4": "024",
    "num5": "037",
    "num6": "045",
    "winrank": "고정"
  }
]



export const fixNums = {
  "trCode": "9999909999",
  "weekNo": "9999",
  "workDate": "9999_99991224",
  "winYn": "N",
  "weekDate": "",
  "newYn": "N",
  "datas": [
    {
      "trcode": "9999909999",
      "weekno": "9999",
      "weekdate": "",
      "seq": 1,
      "winyn": "N",
      "type": "f",
      "num1": "003",
      "num2": "009",
      "num3": "012",
      "num4": "024",
      "num5": "037",
      "num6": "045",
      "winamt": 0,
      "winrank": "고정",
      "history": "",
      "rweekno": "0000",
      "rwin1": "0",
      "rwin2": "0",
      "rwin3": "0",
      "rwin4": "0",
      "rwin5": "0",
      "trseq": 1,
      "trseqmax": 1,
      "workdate": "9999_20230908"
    },
  ]
}