import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography, Box, styled, Slider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useNavigate } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import UndoIcon from '@mui/icons-material/Undo';
import SearchIcon from '@mui/icons-material/Search';

import { debug } from 'utils/common';
import { curWeekNo } from 'utils/lottoCommon';
import { FIND_HIGH_TO_ROW_REQUEST } from 'reducers/anal';
import { Fullscreen } from '@mui/icons-material';

import Condition from './Condition';

const DEBUG_TYPE = 'HighToRow'
const ScrolledAppBar = styled(AppBar)(({ scrolled }) => ({
  transition: 'background-color 0.3s ease',
  backgroundColor: scrolled ? 'white' : 'grey',
  height: scrolled ? '32px' : '56px', // AppBar 높이 변경
  '& .MuiToolbar-root': {
    minHeight: scrolled ? '32px' : '56px', // Toolbar 높이 변경
  },
}));

const ScrolledTypography = styled(Typography)(({ scrolled }) => ({
  fontSize: scrolled ? '1rem' : '1.25rem', // 작은 폰트 크기
  color: scrolled ? 'grey' : 'white', // 작은 폰트 크기
  marginLeft: '6px'
}));
const ScrolledUndoIcon = styled(UndoIcon)(({ scrolled }) => ({
  fontSize: scrolled ? '1rem' : '1.25rem', // 작은 폰트 크기
  color: scrolled ? 'grey' : 'white', // 작은 폰트 크기
}));

const UP = 'UP';
const DOWN = 'DOWN';

const HighToRow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sort, setSort] = React.useState(DOWN);
  const [maxWeekNo, setMaxWeekNo] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [range, setRange] = useState([1, 1000]);
  const [data, setData] = useState([]);
  const {
    findHighToRowLoading,
    findHighToRowDone,
    findHighToRowError,
    findHighToRowData,
  } = useSelector(state => state.anal);  // Array Type
  const minDistance = 20;
  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setRange([clamped - minDistance, clamped]);
      }
    } else {
      setRange(newValue);
    }

  };
  const valuetext = (value) => {
    return `${value}°C`;
  }
  // 스크롤리 멈추면 실행되는 코드 
  const handleSliderChangeCommitted = (event) => {
    debug(DEBUG_TYPE, 'handleSliderDragEnd', event)
    handelFindClick();
  }
  // =======================================================
  // 초기 로딩시 .
  // =======================================================
  useEffect(() => {
    debug(DEBUG_TYPE, "Mounted ~~~~~~");
    const findCurWeekNo = curWeekNo(); // 마지막 추첨 회자 구하기 ( 2002.12.01 ~ 현재일까지 몇주차 ?)
    debug(DEBUG_TYPE, "findCurWeekNo", findCurWeekNo);
    setMaxWeekNo(findCurWeekNo);
    const defaultRange = [findCurWeekNo - 100, findCurWeekNo]
    setRange(defaultRange);
    // 스크롤 이벤트 핸들러를 추가하여 스크롤 상태를 감지합니다.
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);
    // 데이타 조회 
    const rangeCond = { fromWeekNo: defaultRange[0], toWeekNo: defaultRange[1], maxWeekNo: findCurWeekNo }
    dispatch({
      type: FIND_HIGH_TO_ROW_REQUEST,
      data: rangeCond
    });
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // =======================================================
  // 데이타 조회 완료 시점
  // =======================================================
  useEffect(() => {
    // 조회 요청시 = findHighToRowDone : false, 조회 완료 = findHighToRowDone : true,
    if (findHighToRowDone) {
      debug(DEBUG_TYPE, "데이타 조회 완료 후 - findHighToRowData : ", findHighToRowData)
    }
    const findData = findHighToRowData.map((item) => ({
      name: item.num,
      range: item.value,
      total: item.total,
    }));
    setData(findData)
  }, [findHighToRowDone])
  // =======================================================
  // 검색 버튼 클릭시
  // =======================================================
  const handelFindClick = () => {
    debug(DEBUG_TYPE, 'handleIcohandelFindClicknClick, range ', range)
    const rangeCond = { fromWeekNo: range[0], toWeekNo: range[1], maxWeekNo: maxWeekNo }
    dispatch({
      type: FIND_HIGH_TO_ROW_REQUEST,
      data: rangeCond
    });
  }
  // =======================================================
  // 정렬 변경시 
  // =======================================================
  const handleSortChange = (event) => {
    const value = event.target.value
    setSort(value);
    debug(DEBUG_TYPE, 'handleSortChange', value)
    // 배열을 tot - value 값의 오름차순으로 정렬
    // const sortedFindData = findData.sort((a, b) => (a.total - a.range) - (b.total - b.range));
    // 배열을 tot - value 값의 내림차순으로 정렬
    // const sortedFindData = findData.sort((a, b) => (b.total - b.range) - (a.total - a.range));
    const targetData = [...data];
    debug(DEBUG_TYPE, 'data 정렬 전 ', targetData)
    if (value === UP) {
      const sortedData = targetData.sort((a, b) => (a.range - b.range));
      setData(sortedData)
      debug(DEBUG_TYPE, 'sortedData 정렬 후(오름) ', sortedData)
    } else {
      const sortedData = targetData.sort((a, b) => (b.range - a.range));
      setData(sortedData)
      debug(DEBUG_TYPE, 'sortedData 정렬 후(내림) ', sortedData)
    }
  };

  // =======================================================
  // 돌아가기 버튼 클릭 
  // =======================================================
  const handelIconClick = () => {
    debug(DEBUG_TYPE, 'handleIconClick')
    navigate('/Anal');
  }


  return (
    <div>
      <ScrolledAppBar position="fixed" scrolled={scrolled}>
        <Toolbar>
          <ScrolledUndoIcon scrolled={scrolled} onClick={handelIconClick} />
          <ScrolledTypography color="inherit" scrolled={scrolled}>
            {scrolled ? '번호별 출현횟수' : '번호별 출현횟수'}
          </ScrolledTypography>
        </Toolbar>
      </ScrolledAppBar>
      {/* <div style={{ marginTop: scrolled ? '34px' : '58px', marginBottom: '60px' }}> */}
      <div style={{ marginTop: '58px', marginBottom: '60px' }}>  {/* AppBar 높이만큼 공간을 확보하기 위해 marginTop 추가 */}
        <div style={{ height: '100px' }}>
          {/* <Grid container spacing={1} style={{ margin: '10px' }}>
            <Grid item style={{ flex: '0 0 90px' }}>
              <FormControl sx={{ m: 1, minWidth: 80 }} size="small" style={{ marginLeft: 'auto' }}>
                <InputLabel id="demo-select-small-label">Sort</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={sort}
                  label="Sort"
                  onChange={handleSortChange}
                >
                  <MenuItem value={UP}>오름</MenuItem>
                  <MenuItem value={DOWN}>내림</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={Fullscreen} style={{ marginTop: '20px', justifyContent: 'right' }}>
              <span sytle={{ width: '60px', fontSize: '10px' }} >스크롤 : 조회기간 설정</span>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  value={range}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderChangeCommitted}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={1}
                  max={maxWeekNo}
                />
              </Box>
            </Grid>
          </Grid> */}

          <Condition
            handleSortChange={handleSortChange}
            sort={sort}
            UP={UP}
            DOWN={DOWN}
            Fullscreen={Fullscreen}
            range={range}
            handleSliderChange={handleSliderChange}
            handleSliderChangeCommitted={handleSliderChangeCommitted}
            valuetext={valuetext}
            maxWeekNo={maxWeekNo}
          />
        </div>
        <ResponsiveContainer width="100%" height={1500}>
          <LineChart
            layout="vertical"
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Legend />
            <Line dataKey="total" stroke="#f44336" />
            <Line dataKey="range" stroke="#4caf50" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div >
  );
}

export default HighToRow;
