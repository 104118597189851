import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';

import { Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Container, Button, ButtonGroup, Paper } from '@mui/material';
import { PhotoCamera, TrackChangesOutlined } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StoreIcon from '@mui/icons-material/Store';
import SaveIcon from '@mui/icons-material/Save';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { purple } from '@mui/material/colors';

import { findData, deleteData, insertData, LOTTO_MINE } from 'utils/dbCommon';
import { List, ListItem } from '@mui/material';
import { FIND_WEEK_REQUEST, CONFIRM_LOTTO_REQUEST, SET_LOTTO_REQUEST } from 'reducers/home';
import { REQUEST_QRSCAN_CONFIRM_REQUEST } from 'reducers/saveNum';

import Slip from 'components/lotto-mynum/Slip';
import LottoChoice from 'components/lotto-choice/LottoChoice';
import WeekWinConfirm from 'components/lotto-result/WeekWinConfirm';
import MessageAlert from 'components/MessageAlert';
import LottoFixReg from 'components/lotto-choice/LottoFixReg';

import { cameraScan, dataParser } from 'utils/brige.js';
import { MYNUM_BTN_COLOR, QR_BTN_COLOR, APP_BAR_BACKGROUND_COLOR } from 'utils/constValue';

import { debug } from 'utils/common';

const DEBUG_TYPE = 'SaveNum';
const FIX_TR_CODE = '0';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
});


const SaveNum = () => {
  const [openMsg, setOpenMsg] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const [slip, setSlip] = useState([]);
  const [openDetail, setOpenDetail] = useState(false)

  // const [confirmArrayData, setConfirmArrayData] = useState([]); //당첨확인시 전달하는 값
  const [confirmType, setConfirmType] = useState('WEEK'); // QR/WEEK QR확인인지, WEEK확인인
  const [newYn, setNewYn] = useState('N');

  // 
  const [openFixReg, setOpenFixReg] = useState(false);
  const [title, setTitle] = useState('');


  // Store 변수.
  const {
    confirmLottoData,
    weekData,
  } = useSelector(state => state.home);  // Array Type

  // Store 변수.
  const {
    detailDialogOpened,
    qrScanLoading,
    qrScanDone,
    qrScanError,
    qrScanData,   // {}
    fixLottoLoading,
    fixLottoDone,
    fixLottoError,
    fixLottoData,  // {}
  } = useSelector(state => state.saveNum);  // Array Type


  useEffect(() => {
    debug(DEBUG_TYPE, 'weekData 변화 감지', weekData)
  }, [weekData])

  const dispatch = useDispatch();

  useEffect(() => {
    debug(DEBUG_TYPE, 'SaveNum is mounted .....')
    const fetchData = async () => {
      await initProc()
    };
    fetchData();
    // 브리지 함수를 전역으로 등록하여 웹뷰에서 호출할 수 있게 만듧
    window.CallBack = CallBack;
    return () => {
      // 컴포넌트가 언마운트될 때 브리지 함수 해제 ------> 중요 *************************
      delete window.CallBack;
    };
  }, []);

  const initProc = async () => {
    debug(DEBUG_TYPE, 'initProc 호출 ...... ')
    // async function asyncFindData() {
    //   const resultData = await findData(LOTTO_MINE, 'key', '%');
    //   return resultData;
    // }
    const keyName = 'key';
    const key = '%'
    const saveDatas = await findData(LOTTO_MINE, keyName, key);
    // weekno 기준으로 가장 최근 주차부터 보여지도록 
    saveDatas.sort((a, b) => b.weekNo - a.weekNo);

    for (let i = 0; i < saveDatas.length; i++) {
      saveDatas[i].newYn = 'N'
    }
    dispatch({
      type: CONFIRM_LOTTO_REQUEST,
      data: saveDatas
    });
  }
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // QR 스캔 및 스캔데이타 저장.
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleQRSave = useCallback(() => {
    debug(DEBUG_TYPE, '[ Home ] handleSave 호출, save Data ', confirmLottoData);
    const newConfirmArrayData = confirmLottoData.map(item => {
      if (item.datas[0].winyn === 'Y') {
        return {
          ...item
          , winYn: item.datas[0].winyn
          , weekDate: item.datas[0].weekdate
        };
      }
      return item;
    });
    // setConfirmArrayData(newConfirmArrayData);
    insertData(LOTTO_MINE, 'trCode', newConfirmArrayData);
    setNewYn('N');
  }, [confirmLottoData]);

  const handleQRDelete = async () => {
    await deleteData(LOTTO_MINE, confirmLottoData[0].trCode);
    setNewYn('Y');
  };
  // =======================================================================
  const handleQrOpen = () => {
    debug(DEBUG_TYPE, 'QR 스캔버튼 클릭 ');
    setConfirmType('QR');
    cameraScan();  // 안드로이드에서 callBack 함수를 호출해준다. 
    // debug(DEBUG_TYPE, '[ Home ] QR 스캔버튼 data ', confirmQrScanData);
  }
  // ***********************************************
  //   안드리이드 웹뷰에서 브릿지를 통해 call 하는 전역 함수. 
  // ***********************************************
  const CallBack = async (scanData, scanType) => {
    debug(DEBUG_TYPE, 'CallBack 함수 호출', scanType, scanData);
    const slipTitile = 'QR 스캔';
    setTitle(slipTitile)
    const qrScanSlip = dataParser(scanData);
    debug(DEBUG_TYPE, 'qrScanData dataParser', qrScanSlip);
    const scanTrCode = qrScanSlip.trCode;
    const savedData = await findData(LOTTO_MINE, 'key', scanTrCode);
    let newYnVal = 'N';
    if (savedData.length > 0) {
      qrScanSlip.newYn = 'Y'
      newYnVal = 'N';
      setNewYn('N');
    } else {
      qrScanSlip.newYn = 'Y'
      newYnVal = 'Y';
      setNewYn('Y');
    }
    const findTypeWeekno = {
      type: 'DETAIL',
      weekNo: qrScanSlip.weekNo
    }
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
    });
    dispatch({
      type: REQUEST_QRSCAN_CONFIRM_REQUEST,
      data: qrScanSlip,
    });
    setOpenDetail(true);
  }
  useEffect(() => {
    debug(DEBUG_TYPE, 'qrScan 당첨확인 후', qrScanData)
    if (qrScanDone === true) {
      const confrimQrScanSlips = []
      confrimQrScanSlips.push(qrScanData);
      debug(DEBUG_TYPE, 'qrScan 당첨확인 slip 에 저장', confrimQrScanSlips)
      setSlip(confrimQrScanSlips)
    }
  }, [qrScanDone])
  const handleQrScanSave = () => {
    debug(DEBUG_TYPE, ' handleQrScanSave 호출, save Data ', slip);
    const newSlip = slip.map(item => {
      if (item.datas[0].winyn === 'Y') {
        return {
          ...item
          , winYn: item.datas[0].winyn
          , weekDate: item.datas[0].weekdate
        };
      }
      return item;
    });
    debug(DEBUG_TYPE, ' handleQrScanSave 호출, save Data ', newSlip);
    // setConfirmArrayData(newConfirmArrayData);
    insertData(LOTTO_MINE, 'trCode', newSlip);
    setNewYn('N');
    initProc();
  };
  const handleQrScanDelete = async () => {
    debug(DEBUG_TYPE, 'qrScan Data 삭제(handleQrScanDelete) 호출 ', qrScanData.trCode);
    await deleteData(LOTTO_MINE, qrScanData.trCode);
    setNewYn('Y');
    initProc();
  }
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 내 조겅 번호 저장. 
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleFixNoOpen = () => {
    debug(DEBUG_TYPE, 'handleFixNoOpen 호출 ');
    const slipTitile = '내번호 저장';
    setTitle(slipTitile)
    setOpenFixReg(true);
  }
  const handleFixRegClose = () => {
    debug(DEBUG_TYPE, 'handleFixRegClose 호출 ');
    setOpenFixReg(false);
    initProc();
  }
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 전표별 상세 확인창 띄우기.
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  useEffect(() => {
    debug(DEBUG_TYPE, 'detailDialogOpened 변화 감지 ', detailDialogOpened);
  }, [detailDialogOpened])

  const handleDetail = useCallback((slip) => {
    debug(DEBUG_TYPE, '전표 상세보기(handleDetail) 호출 ', slip);
    const slipTitile = '상세내역 보기';
    setTitle(slipTitile)
    setConfirmType('WEEK')
    const newSlip = {
      ...slip,
      newYn: 'Y'
    }
    const slipArray = [];
    slipArray.push(newSlip)
    setSlip(slipArray);
    setOpenDetail(true);
    const findTypeWeekno = {
      type: 'DETAIL',
      weekNo: slip.weekNo
    }
    dispatch({
      type: FIND_WEEK_REQUEST,
      data: findTypeWeekno,
    });
  }, []);

  const handleDetailDialogClose = useCallback(() => {
    setOpenDetail(false);
  }, []);

  const handleDelete = (trCode) => {
    debug(DEBUG_TYPE, '전표 삭제(handleDelete) 호출 ', trCode);
    const syncDelete = async (key) => {
      debug(DEBUG_TYPE, `전표 삭제(handleDelete) syncDelete 함수 호출 ( ${trCode}) `, trCode);
      await deleteData(LOTTO_MINE, key);
    };
    syncDelete(trCode);
    initProc();
  }

  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // 하단에 알림 메세지 띄우기 
  // 메세지 내용은 setAlertMessage(메세지내용)으로 셋업
  // 메세지 띄우기 setOpenMsg(true), 당기 setOpenMsg(false);
  // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const handleMsgOpen = useCallback(() => {
    setOpenMsg(true);
  }, []);

  const handleMsgClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsg(false);
  }, []);


  const boxCSS = useMemo(() => {
    return {
      marginBottom: '80px',
    }
  }, [])

  const buttonGrpCSS = useMemo(() => {
    return {
      marginTop: '10px',
      paddingLeft: '16px',
      paddingRight: '16px',
    }
  }, [])
  const qrBtnStyle = useMemo(() => {
    return {
      backgroundColor: QR_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const fixBtnStyle = useMemo(() => {
    return {
      backgroundColor: MYNUM_BTN_COLOR, // 원하는 배경색
      color: 'white', // 원하는 텍스트 색상
    }
  }, []);
  const slipCSS = useMemo(() => {
    return {
      paddingTop: '6px',
      paddingBottom: '6px'
    }
  }, [])
  const appBarStyle = useMemo(() => {
    return {
      backgroundColor: '#22668D', // 원하는 텍스트 색상
    }
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBar position='relative' style={appBarStyle}>
        <Toolbar>
          <SaveIcon />
          <Typography variant='h6' style={{ paddingLeft: "10px" }}>
            내 번호
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <div style={buttonGrpCSS} >
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            fullWidth={true}
          >
            <ThemeProvider theme={theme}>
              <Button
                style={qrBtnStyle}
                startIcon={<QrCodeIcon />}
                onClick={handleQrOpen}
              >QR스캔</Button>
              <Button
                style={fixBtnStyle}
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={handleFixNoOpen}
              // component={Link} to="/Home/WinConfirm/1111"
              >고정번호</Button>
            </ThemeProvider>
          </ButtonGroup>
        </div>
        <Container style={boxCSS}>
          <List>
            {
              confirmLottoData.length > 0 ? (
                confirmLottoData.map((item, index) => (
                  <ListItem key={item.trCode + index} sx={{ px: '0px' }} style={slipCSS}>
                    <Slip
                      key={item.trCode}
                      slipData={item}
                      handleDelete={handleDelete}
                      handleDetail={handleDetail}
                    />
                  </ListItem>
                ))
              ) : (
                <div>자료가 존재하지 않습니다. WeekWinConfirm component</div>
              )
            }
          </List>
        </Container>
      </main>
      <WeekWinConfirm
        open={openDetail}
        title={title}
        handleClose={handleDetailDialogClose}
        slipDatas={slip} // 확인된 전표 
        weekData={weekData} // 회차정보 ( 일자 )
        confirmType={confirmType}  // QR , WEEK
        newYn={newYn}  // Y:신규, N:저장된데이타, R:갱신대상. 
        handleSave={handleQrScanSave}
        handleDelete={handleQrScanDelete}
        handleQrOpen={handleQrOpen}
      />
      <LottoFixReg
        open={openFixReg}
        title={title}
        handleClose={handleFixRegClose}
      />
      <MessageAlert
        openMessage={openMsg}
        handleClose={handleMsgClose}
        message={alertMessage}
      />
    </>
  );
};
export default SaveNum