import React, { useMemo, useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';

import { debug } from 'utils/common';
const DEBUG_TYPE = 'Ball_'

const Ball = (props) => {
  /** 
    props : 
    {
        ballSize : sm/md/lg
        ballNum  : 1 ~ 45
        colorYn  : Y / N 
    }
  */
  const [ballSize, setBallSize] = useState();
  const [ballNum, setBallNum] = useState();
  const [colorYn, setColorYn] = useState();

  useEffect(() => {
    debug(DEBUG_TYPE, 'ball props : ', props);
    setBallSize(props.state.ballSize);
    setBallNum(props.state.ballNum);
    setColorYn(props.state.colorYn);
  }, [props])

  const BallSize = useMemo(() => {
    let rtn = { width: 22, height: 22, fontSize: 12 };
    if (ballSize === 'sm') {
      rtn = { width: 18, height: 18, fontSize: 10 };
    } else if (ballSize === 'md') {
      rtn = { width: 26, height: 26, fontSize: 14 };
    } else if (ballSize === 'lg') {
      rtn = { width: 36, height: 36, fontSize: 20 };
    };
    return rtn;
  }, [ballSize]);

  const BallColor = useMemo(() => {
    let rtn = {};
    if (colorYn === 'N') {
      return rtn;
    }
    if (ballNum >= 1 && ballNum <= 10) {
      // 노랑
      return { backgroundColor: '#f4cf77', color: '#fff' };
    } else if (ballNum >= 11 && ballNum <= 20) {
      // 파랑
      return { backgroundColor: '#6aa7e8', color: '#fff' };
    } else if (ballNum >= 21 && ballNum <= 30) {
      // 빨강
      return { backgroundColor: '#ff7666', color: '#fff' };
    } else if (ballNum >= 31 && ballNum <= 40) {
      // 검정
      return { backgroundColor: '#5a5e62', color: '#fff' };
    } else if (ballNum >= 41 && ballNum <= 45) {
      // 초록
      return { backgroundColor: '#98e187', color: '#fff' };
    }
    return rtn;
  }, [ballNum, colorYn]);

  return (
    <>
      <Avatar sx={BallSize} style={BallColor}>
        {ballNum !== 0 ? ballNum : ''}
      </Avatar>
    </>
  )
}

Ball.propTypes = {
  props: PropTypes.object,
};
/*
FollowList.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.number,
  User: PropTypes.object,
  content: PropTypes.string,
  createdAt: PropTypes.string,
  Comments: PropTypes.arrayOf(PropTypes.object),
  Images: PropTypes.arrayOf(PropTypes.object),
  Likers: PropTypes.arrayOf(PropTypes.object),
  RetweetId: PropTypes.number,
  Retweet: PropTypes.objectOf(PropTypes.any),
};
*/

export default Ball