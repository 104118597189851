import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Container,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { debug } from 'utils/common';
import LottoChoice from './LottoChoice';
import Slip from 'components/lotto-mynum/Slip';
import ResultFixDetailBalls from 'components/lotto-result/ResultBalls';

import { CONFIRM_BTN_COLOR, QR_BTN_COLOR, APP_BAR_BACKGROUND_COLOR } from 'utils/constValue';

const DEBUG_TYPE = 'LottoFixReg'

/**
 * 사용하는 화면에서 Dialog 로 사용됨. 
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500]
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
});

const LottoFixReg = (props) => {
  const open = props.open;
  const handleClose = props.handleClose;
  const title = props.title;
  const [choiceNums, setChoiceNums] = useState([]);

  const [fixNums, setFixNums] = useState([]);

  const intiSet = () => {
    const choiceNumsInit = [];
    for (let num = 1; num <= 45; num++) {
      choiceNumsInit.push({ num: num, choiced: false });
    }
    setChoiceNums(choiceNumsInit);

  }
  useEffect(() => {
    debug(DEBUG_TYPE, 'props ', props)
    intiSet();
  }, []);

  const appBarStyle = useMemo(() => {
    return {
      backgroundColor: APP_BAR_BACKGROUND_COLOR, // 원하는 텍스트 색상
    }
  }, []);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position='relative' style={appBarStyle}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>

        <Container>
          <LottoChoice></LottoChoice>

          {/* 
          {
            (fixNums && fixNums.length > 0) ? (
              fixNums.map((item, index) => (
                <React.Fragment key={item.trcode + '-' + index} >
                  <ResultFixDetailBalls key={item.trcode + '-' + item.seq} balls={item} />
                </React.Fragment>
              ))
            ) : (
              null
            )
          } 
          */}

        </Container>

      </Dialog >
    </>
  )
}

export default LottoFixReg;