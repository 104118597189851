import { all, fork } from 'redux-saga/effects';

import userSaga from './user';
import homeSaga from './home';
import saveNumSaga from './saveNum';
import storelottoSaga from './storelotto';
import analSaga from './anal';

// axios.defaults.baseURL = 'http://nicework.co.kr:3030';
// axios.defaults.withCredentials = true;

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(homeSaga),
    fork(saveNumSaga),
    fork(storelottoSaga),
    fork(analSaga)
  ]);
}

/*
http://niceworker.cafe24.com/api/lotto/data/982
http://niceworker.cafe24.com/api/lotto/data
http://niceworker.cafe24.com/api/lotto/mydata   : post [ data ]
http://niceworker.cafe24.com/api/lotto/store/37.5814329/126.8909878
http://niceworker.cafe24.com/api/lotto/ainum/961
*/

