export const DEBUG_TYPE = 'A';  // A 전부 출력, 특정값 에서만 출력 
export const DEBUG_SAGA = 'SAGA';
export const DEBUG_ERROR = 'E';

/* -----------------------------------------------------

----------------------------------------------------- */
export const debug = (type, text, value1 = null, value2 = null, value3 = null) => {

  const debugTargetList = [
    "DEBUG"
    // , 'Home'  // Home 페이지.
    // , 'BuyResult'
    // , 'lottoCommon'
    // , 'SaveNum'   // My Number 두번째 페이지.
    // , 'Storelotto'
    // , 'Slip' // Slip Component
    // , 'WeekWinConfirm'  // 
    // , 'LottoChoice'
    // , 'ResultFixDetailBalls'
    // , 'Ball'
    // , 'reducers_saveNum'
    // , 'reducers_home'
    // , 'saga_home'
    // , 'saga_saveNum'
    // , 'reducers_store'
    // , 'sagas_store'
    // , 'dbCommon'
    // , 'brige'
    , 'HighToRow'
    // , 'reducers_anal'
    // , 'saga_anal'
  ]

  // ex) debug('A', 'debug test', '1234');
  if (debugTargetList.includes(type)) {
    if (value3 != null) {
      console.log(`[${type}]${text}`, value1, value2, value3)
    } else if (value2 != null) {
      console.log(`[${type}]${text}`, value1, value2)
    } else if (value1 != null) {
      console.log(`[${type}]${text}`, value1)
    } else {
      console.log(`[${type}]${text}`)
    }
  }
  //  ==========================================
  if (type === DEBUG_SAGA) {
    if (value3 != null) {
      console.log(text, value1, value2, value3)
    } else if (value2 != null) {
      console.log(text, value1, value2)
    } else if (value1 != null) {
      console.log(text, value1)
    } else {
      console.log(text)
    }
  }
  //  ==========================================
  if (type === DEBUG_ERROR) {
    if (value3 != null) {
      console.error(text, value1, value2, value3)
    } else if (value2 != null) {
      console.error(text, value1, value2)
    } else if (value1 != null) {
      console.error(text, value1)
    } else {
      console.error(text)
    }
  }
};

/* -----------------------------------------------------
  moment.js 를 이용하는 법

──────────────────────────────────────────────────────────────────────
Format Dates
......................................................................
moment().format('MMMM Do YYYY, h:mm:ss a'); // 2월 16일 2022, 2:32:47 오후
moment().format('dddd');                    // 수요일
moment().format("MMM Do YY");               // 2월 16일 22
moment().format('YYYY [escaped] YYYY');     // 2022 escaped 2022
moment().format();                          // 2022-02-16T14:32:47+09:00
──────────────────────────────────────────────────────────────────────
Relative Time
......................................................................
moment("20111031", "YYYYMMDD").fromNow(); // 10년 전
moment("20120620", "YYYYMMDD").fromNow(); // 10년 전
moment().startOf('day').fromNow();        // 15시간 전
moment().endOf('day').fromNow();          // 9시간 후
moment().startOf('hour').fromNow();       // 33분 전
──────────────────────────────────────────────────────────────────────
Calendar Time
......................................................................
moment().subtract(10, 'days').calendar(); // 2022.02.06.
moment().subtract(6, 'days').calendar();  // 지난주 목요일 오후 2:33
moment().subtract(3, 'days').calendar();  // 지난주 일요일 오후 2:33
moment().subtract(1, 'days').calendar();  // 어제 오후 2:33
moment().calendar();                      // 오늘 오후 2:33
moment().add(1, 'days').calendar();       // 내일 오후 2:33
moment().add(3, 'days').calendar();       // 토요일 오후 2:33
moment().add(10, 'days').calendar();      // 2022.02.26.
──────────────────────────────────────────────────────────────────────
Multiple locale Support
......................................................................
moment.locale();         // ko
moment().format('LT');   // 오후 2:33
moment().format('LTS');  // 오후 2:33:57
moment().format('L');    // 2022.02.16.
moment().format('l');    // 2022.02.16.
moment().format('LL');   // 2022년 2월 16일
moment().format('ll');   // 2022년 2월 16일
moment().format('LLL');  // 2022년 2월 16일 오후 2:33
moment().format('lll');  // 2022년 2월 16일 오후 2:33
moment().format('LLLL'); // 2022년 2월 16일 수요일 오후 2:33
moment().format('llll'); // 2022년 2월 16일 수요일 오후 2:33
──────────────────────────────────────────────────────────────────────
Time from Now
......................................................................
moment([2007, 0, 29]).fromNow();     // 4 years ago
moment([2007, 0, 29]).fromNow(true); // 4 years
──────────────────────────────────────────────────────────────────────
Time from x
......................................................................
var a = moment([2007, 0, 28]);
var b = moment([2007, 0, 29]);
a.from(b);                     // "a day ago"
a.from([2007, 0, 29]);         // "a day ago"
a.from(new Date(2007, 0, 29)); // "a day ago"
a.from("2007-01-29");          // "a day ago"
──────────────────────────────────────────────────────────────────────
Time to Now
......................................................................
moment([2007, 0, 29]).toNow();     // in 4 years
moment([2007, 0, 29]).toNow(true); // 4 years
──────────────────────────────────────────────────────────────────────
ADD 
......................................................................
moment().add(7, 'days');
// ==
moment().add(7, 'd');

// 시간을 다양하게 더할 수 있다
moment().add(1000000, 'milliseconds'); // a million milliseconds
moment().add(360, 'days'); // 360 days
----------------------------------------------------- */