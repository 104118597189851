import produce from '../utils/produce';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'reducers_saveNum_'

export const initialState = {
  //
  detailDialogOpen: false,
  // 
  qrScanLoading: false,
  qrScanDone: false,
  qrScanError: null,
  qrScanData: {},
  //
  fixLottoLoading: false,
  fixLottoDone: false,
  fixLottoError: null,
  fixLottoData: {},
  //
  choiceLottoLoading: false,
  choiceLottoDone: false,
  choiceLottoError: null,
  choiceLottoData: {},
};

export const SET_FIX_SLIP = 'SET_FIX_SLIP';

export const REQUEST_QRSCAN_CONFIRM_REQUEST = 'REQUEST_QRSCAN_CONFIRM_REQUEST';
export const REQUEST_QRSCAN_CONFIRM_SUCCESS = 'REQUEST_QRSCAN_CONFIRM_SUCCESS';
export const REQUEST_QRSCAN_CONFIRM_FAILUR = 'REQUEST_QRSCAN_CONFIRM_FAILUR';

export const REQUEST_FIXLOTTO_CONFIRM_REQUEST = 'REQUEST_FIXLOTTO_CONFIRM_REQUEST';
export const REQUEST_FIXLOTTO_CONFIRM_SUCCESS = 'REQUEST_FIXLOTTO_CONFIRM_SUCCESS';
export const REQUEST_FIXLOTTO_CONFIRM_FAILUR = 'REQUEST_FIXLOTTO_CONFIRM_FAILUR';

export const REQUEST_CHOICE_CONFIRM_REQUEST = 'REQUEST_CHOICE_CONFIRM_REQUEST';
export const REQUEST_CHOICE_CONFIRM_SUCCESS = 'REQUEST_CHOICE_CONFIRM_SUCCESS';
export const REQUEST_CHOICE_CONFIRM_FAILUR = 'REQUEST_CHOICE_CONFIRM_FAILUR';

export const DETAIL_DIALOG_OPEN = 'DETAIL_DIALOG_OPEN';



const dummyUser = (data) => ({
  ...data,
  nickname: '제로초',
  id: 1,
  Posts: [{ id: 1 }],
  Followings: [{ nickname: '부기초' }, { nickname: 'Chanho Lee' }, { nickname: 'neue zeal' }],
  Followers: [{ nickname: '부기초' }, { nickname: 'Chanho Lee' }, { nickname: 'neue zeal' }],
});

/*
  1. component 에서 dispatch 호출 
    dispatch({
      type: LOG_IN_REQUEST,
    });
  2. reducer 자동 실행됨. 
  3. reducer 
*/
const reducer = (state = initialState, action) => produce(state, (draft) => {
  debug(DEBUG_TYPE, "action.type : ", action.type);
  debug(DEBUG_TYPE, "action.data : ", action.data);
  switch (action.type) {
    // -------------------------------
    case REQUEST_QRSCAN_CONFIRM_REQUEST:
      draft.qrScanLoading = true;
      draft.qrScanDone = false;
      draft.qrScaneError = null;
      break;
    case REQUEST_QRSCAN_CONFIRM_SUCCESS:
      draft.qrScanLoading = false;
      draft.qrScanDone = true;
      draft.qrScaneError = null;
      draft.qrScanData = action.data
      break;
    case REQUEST_QRSCAN_CONFIRM_FAILUR:
      draft.qrScanLoading = false;
      draft.qrScaneError = action.error;
      break;
    // -------------------------------
    case SET_FIX_SLIP:
      draft.fixLottoData = action.data
      break;
    case REQUEST_FIXLOTTO_CONFIRM_REQUEST:
      draft.fixLottoLoading = true;
      draft.fixLottoDone = false;
      draft.fixLottoeError = null;
      break;
    case REQUEST_FIXLOTTO_CONFIRM_SUCCESS:
      draft.fixLottoLoading = false;
      draft.fixLottoDone = true;
      draft.fixLottoError = null;
      draft.fixLottoData = action.data
      break;
    case REQUEST_FIXLOTTO_CONFIRM_FAILUR:
      draft.fixLottoLoading = false;
      draft.fixLottoError = action.error;
      break;
    //
    case REQUEST_CHOICE_CONFIRM_REQUEST:
      draft.choiceLottoLoading = true;
      draft.choiceLottoDone = false;
      draft.choiceLottoeError = null;
      break;
    case REQUEST_CHOICE_CONFIRM_SUCCESS:
      draft.choiceLottoLoading = false;
      draft.choiceLottoDone = true;
      draft.choiceLottoError = null;
      draft.choiceLottoData = action.data
      break;
    case REQUEST_CHOICE_CONFIRM_FAILUR:
      draft.choiceLottoLoading = false;
      draft.choiceLottoError = action.error;
      break;
    //
    case DETAIL_DIALOG_OPEN:
      draft.detailDialogOpen = action.data;
      break;
    default:
      break;
  }
});

export default reducer;
