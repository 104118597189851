import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SaveIcon from '@mui/icons-material/Save';
import StoreIcon from '@mui/icons-material/Store';
import HomeIcon from '@mui/icons-material/Home';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';

const Footer = () => {
  const [value, setValue] = useState(0);
  const positon = useMemo(() => {
    return { position: 'fixed', bottom: 0, left: 0, right: 0 };
  }, []);
  return (

    <Paper sx={positon} elevation={3}>
      <BottomNavigation
        // showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Home" value="Home" icon={<HomeIcon />} component={Link} to="/" />
        <BottomNavigationAction label="내번호" value="SaveNum" icon={<SaveIcon />} component={Link} to="/SaveNum" />
        <BottomNavigationAction label="분석" value="Info" icon={<QueryStatsIcon />} component={Link} to="/Anal" />
        <BottomNavigationAction label="판매점" value="Store" icon={<StoreIcon />} component={Link} to="/Store" />
        {/* <BottomNavigationAction label="정보" value="Settin" icon={<SettingsIcon />} component={Link} to="/Setting" /> */}
      </BottomNavigation>
    </Paper>
  )
}

export default Footer