import { slipDatas } from 'sampleData/jsonSample';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'dbCommon'

export const DATABASE_NAME = 'NiceWorkLotto';   // database name 
export const LOTTO_MINE = 'LOTTO_MINE'  // store name : 로또 구매 정보.
export const MINE_AUDIT = 'MINE_AUDIT'  // store name : 구매/당첨 정산 .



export const findWeekData = async (weekNO) => {
  return slipDatas;
}


// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/*
  - 브라우저는 여러개의 Database를 가질 수 있습니다. 
  - Database는 Version정보가 있고, 여러개의 ObjectStore를 가질 수 있습니다.
    ( Database 수정시에는 Version을 수정해야 합니다.) 
  - indexedDB.open(db_name, version)함수로 Database를 열도록 요청합니다. 

  initDatabase : 처음 App 실행시 데이타베이스 및 스토어 만들기 
  insertData(storeName, key, data) : data를 store에 저장하기 
    ex) insertData('LOTTO_MINE', 'trcode', mylotto);
  findData( store, indexName, indexKey , onSuccess, onError)
    ex)  findData( 'LOTTO_MINE'
      ,'key', '%' 
      , function(data){     // --> onSuccess
          if ( data ) {
            // 데이타를 찾은 경우 
            console.log('data found : ' , data);
          } else {
            // 데이타를 못 찾을 경우 
            console.log('data not found. ');
          }
        }
      , function(error){    // --> onError
          // 에러 발생시 
          console.log('Error occurred:', error);
        }
     );
  deleteData (store, key )
    ex) deleteData('LOTTO_MINE','10002' )
*/
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
const config = {
  version: 1,
  stores: [
    { name: LOTTO_MINE, key: 'trCode', subKeys: [{ indexName: 'weekNo', objectName: 'weekNo', uniqueYn: false }] },      // 로또 구매내역 
    { name: MINE_AUDIT, key: 'id', subKeys: [] },          // 로또 구매정산(구매/당첨)
  ]
}

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export const initDataBase = async () => {
  debug(DEBUG_TYPE, '[localDb] initDataBase Start.............................................')
  const VERSION = config.version;
  // 지원여부 확인 
  if (!window.indexedDB) {
    window.alert("browser doesn`t support IndexedDB");
  } else {
    const request = window.indexedDB.open(DATABASE_NAME, VERSION);
    //
    request.onerror = function (event) {
      console.error("[localDb] initDataBase() - open DB Error", event)
      alert('failed');
    };
    // 새로 만들거나 버전이 높을때만 발생하는 이벤트 
    // ObjectStore를 만들거나 수정할때 이 이벤트내에서 진행 
    // onsuccess는 이벤트가 끝나면 발생됩니다. 
    request.onupgradeneeded = function (event) {
      const db = event.target.result;

      try {
        for (let i = 0; i < config.stores.length; i++) {
          let storeName = config.stores[i].name;
          let key = config.stores[i].key;
          let subKeys = config.stores[i].subKeys;

          // 기존 오브젝트 스토어가 있는지 확인하고 필요한 경우 삭제
          if (db.objectStoreNames.contains(storeName)) continue

          // 새로운 오브젝트 스토어 생성
          const newObjectStore = db.createObjectStore(storeName, { keyPath: key });
          // sub index 가 존재시 SUB INDEX 생성하기 .
          for (let j = 0; j < subKeys.length; j++) {
            let indexName = subKeys[i]?.indexName;
            let objectName = subKeys[i]?.objectName;
            // let uniqueYn =  subKeys[i]?.objectName === undefined ? false : true;
            if (indexName !== undefined) {
              // 필요한 경우 인덱스 생성
              newObjectStore.createIndex(indexName, objectName, { unique: false });
            }
          }
        }
        // 기존 오브젝트 스토어가 있는지 확인하고 필요한 경우 삭제
        if (db.objectStoreNames.contains('newObjectStore')) {
          db.deleteObjectStore('newObjectStore');
        }
      } catch (error) {

      }
    }
  };
  debug(DEBUG_TYPE, '[localDb] initDataBase End.............................................')
}

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// 함수 정의
// ex) insertData(LOTTO_MINE, 'trcode', jsonObject)
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export const insertData = async function (storeName, key, data) {
  await initDataBase();
  const request = window.indexedDB.open(DATABASE_NAME);

  request.onerror = function (event) {
    console.error('[localDB] insertDataIfNotExists - Failed to open database', event.target);
  };

  request.onupgradeneeded = function (event) {
    // console.log('[localDB] request.onupgradeneeded',storeName, key)
    const db = event.target.result;
    // 새로운 오브젝트 스토어 생성
    db.createObjectStore(storeName, { keyPath: key });
    // console.log('[localDB] request.onupgradeneeded',storeName, key)
  };

  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    if (data.length <= 0) return

    for (let i = 0; i < data.length; i++) {
      // 이미 스토어가 생성되어 있는 경우 바로 데이터를 저장
      const requestGet = objectStore.get(data[i][key]);  // = objectStore.get(data[i].key)
      requestGet.onsuccess = function (event) {
        const existingData = event.target.result;

        if (!existingData) {
          // 스토어에  존재하지 않으면 데이터 인서트 
          objectStore.add(data[i]);
        } else {
          // 스토어가 이미 존재하면 데이터 업데이트 
          objectStore.put(data[i]);
        }
      };

      requestGet.onerror = function (event) {
        console.error('[localDb] insertDataIfNotExists - Failed to get data', event.target);
      };
    }

    transaction.oncomplete = function () {
      db.close();
      // console.log('All done!');
    };

    transaction.onerror = function (event) {
      console.error('[localDb] insertDataIfNotExists - Transaction error', event.target);
    };
  };
}

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
/* =========================================================================================
  ex) findData( STORE_NAME, KEY_NAME, KEY
          , function(data){     ----> onSuccess
              if ( data ) {
                // 데이타를 찾은 경우 
                console.log('data found : ' , data);
              } else {
                // 데이타를 못 찾을 경우 
                console.log('data not found. ');
              }

            }
          , function(error){    ----> onError
              // 에러 발생시 
              console.log('Error occurred:', error);
            }
      );

 async function getDataByKey(db, storeName, key) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);

      const getDataRequest = store.get(key);

      getDataRequest.onsuccess = (event) => {
        const resultData = event.target.result;
        resolve(resultData);
      };

      getDataRequest.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  return (
    <div className="App">
      <h1>IndexedDB Data</h1>
      <input
        type="text"
        placeholder="Enter a key"
        value={inputKey}
        onChange={handleInputChange}
      />
      <p>{result}</p>
    </div>
  );
}

export default App;
위 코드에서 openDB와 getDataByKey 함수는 Promise를 반환하여 비동기적으로 동작합니다. 
이런 방식으로 비동기 작업을 동기처럼 보이게 할 수 있지만, 
일반적으로는 비동기 작업의 특성을 이해하고 그에 따른 비동기 코드를 작성하는 것이 더 효율적입니다.

============================================================================================*/
export const findData = async function (storeName, indexName, indexKey) {
  await initDataBase();
  debug(DEBUG_TYPE, `[localDB]findData('${storeName}', '${indexName}', '${indexKey}' ) 호출 시작`);
  return new Promise((resolve, reject) => {
    debug(DEBUG_TYPE, `[localDB]findData('${storeName}', '${indexName}', '${indexKey}')`, 'Promise 시작');
    const openDBRequest = window.indexedDB.open(DATABASE_NAME);
    openDBRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(storeName, 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const rtnValues = [];
      var getDataRequest = null;
      if (indexName === 'key') {
        getDataRequest = (indexKey === '%' ? objectStore.getAll() : objectStore.getAll(indexKey));
      } else {
        const index = objectStore.index(indexName);
        getDataRequest = index.getAll(indexKey);  // 리턴값이 배열 
      }
      getDataRequest.onsuccess = (event) => {
        const resultObject = event.target.result
        const objectType = Object.prototype.toString.call(resultObject);
        // [object Array], 
        debug(DEBUG_TYPE, `[dbCommon] findData( storeName(${storeName}), indexName(${indexName}), indexKey(${indexKey})) return value `, objectType)
        debug(DEBUG_TYPE, `[dbCommon] findData() => `, resultObject);
        resolve(resultObject);
      };
      getDataRequest.onerror = (event) => {
        reject(event.target.error);
      };
    };

    openDBRequest.onerror = (event) => {
      console.log('debug onError');
      reject(event.target.error);
    };
  });
};


export const findData_bak = async function (store, indexName, indexKey, onSuccess, onError) {
  // console.log(`[localDB]findData(${store}, ${indexName}, ${indexKey}, onSuccess(), onError() )`);
  debug(DEBUG_TYPE, `[localDB]findData('${store}', '${indexName}', '${indexKey}', onSuccess(), onError() ) 호출 시작`);
  await initDataBase();
  var request = window.indexedDB.open(DATABASE_NAME);
  request.onerror = function (event) {
    debug('E', `[localDB]findData('${store}', '${indexName}', '${indexKey}', onSuccess(), onError() ) - Error`, event.target);
    onError(event.target.error);
  }
  // ...................................................................................
  request.onsuccess = function (event) {
    const db = event.target.result;
    // person ObjectStore 에 readwrite(읽기, 쓰기)권한으로 Transaction 생성
    // const transaction = db.transaction(store);
    const transaction = db.transaction([store], 'readonly');
    const objectStore = transaction.objectStore(store);
    if (indexName === 'key') {
      const getRequest = (indexKey === '%' ? objectStore.openCursor() : objectStore.get(indexKey));
      const allData = [];
      getRequest.onsuccess = function (event) {
        var resultObject = event.target.result
        const objectType = Object.prototype.toString.call(resultObject);
        // console.log('resultObject and object Type : ',  resultObject, objectType);

        if (objectType === '[object IDBCursorWithValue]' || objectType === '[object Null]') {
          debug(DEBUG_TYPE, '[dbCommon] findData() default Key 사용 : 배열인경우 ', objectType)
          if (resultObject) {
            // 데이타를 가져와서 allData 배열에 추가
            allData.push(resultObject.value);
            resultObject.continue();
          } else {
            // 데이타를 모두 가져왔을 때 호출
            onSuccess(allData);
          }
        } else {
          debug(DEBUG_TYPE, '[dbCommon] findData() default Key 사용 : ', objectType)
          allData.push(resultObject);
          onSuccess(allData);
        }
      }
      getRequest.onerror = function (event) {
        console.error(`[localDB]findData(${indexName}-${indexKey})-objectStore.get($${indexName}-${indexKey})`, event.target);
        onError(event.target.error);
      }
    } else {
      const index = objectStore.index(indexName);
      const getIndexRequest = index.getAll(indexKey);  // 리턴값이 배열 
      getIndexRequest.onsuccess = function (event) {
        const resultArrayObject = event.target.result;
        if (Array.isArray(resultArrayObject) && resultArrayObject.length === 0) {
          debug(DEBUG_TYPE, `[dbCommon] findData( ${indexName}-${indexKey} ) extra Key 사용 : 배열이 비어있습니다. sample Data로 대체 `, slipDatas)
          onSuccess(resultArrayObject);
        } else {
          onSuccess(resultArrayObject);
        }
      }
      getIndexRequest.onerror = function (event) {
        console.error(`[localDB]findData(${indexName}-${indexKey})-objectStore.get(${indexName}-${indexKey}`, event.target);
        onError(event.target.error);
      }
    }
    // 완료 및 실패 이벤트 제어 
    transaction.onerror = function (event) {
      console.error(`[localDB]findONData(${indexName}-${indexKey})-transaction on error`, event.target);
      onError(event.target.error);
    }
    transaction.oncomplete = function (event) {
      // console.log(`[localDB]findOneData(${indexName}-${indexKey})-transaction is completed ! `);
      db.close();
    }
  }
  // ...................................................................................
};

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//  updateData(store, key, value)
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export const updateData = async function (store, key, value) {
  // console.log(`[localDB]findData(${store}, ${indexName}, ${indexKey}, onSuccess(), onError() )`);
  debug(DEBUG_TYPE, `[localDB]findData('${store}', '${key}', '${value}', onSuccess(), onError() ) 호출 시작`);
  var request = window.indexedDB.open(DATABASE_NAME);

  request.onerror = function (event) {
    console.log(event.garget.errorCode)
  }

  request.onsuccess = function (event) {
    var db = this.result;
    var transaction = db.transaction([store], 'readwrite');

    // 완료 및 실패 이벤트 제어 
    transaction.onerror = function (event) {
      console.log('fail');
    }
    transaction.oncomplete = function (event) {
      console.log('done');
    }

    var objectStore = transaction.objectStore(store);
    var request = objectStore.get(key);
    request.onsuccess = function (event) {
      var data = event.target.result
      data = value;

      var updateRequest = objectStore.put(data);
      updateRequest.onerror = function (event) {
        console.log('update error');
      }
      updateRequest.onsuccess = function (event) {
        console.log('update success');
      }
      transaction.onerror = function (event) {
        console.error('fail');
      }
      transaction.onsuccess = function (event) {
        db.close();
        console.log('done');
      }
    }
  }
};
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//  deleteData(STORE_NAME, TR_CODE)
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export const deleteData = function (store, key) {
  debug(DEBUG_TYPE, 'deteData 함수 호출 store : ', store);
  debug(DEBUG_TYPE, 'deteData 함수 호출 key : ', key);

  var request = window.indexedDB.open(DATABASE_NAME);

  request.onerror = function (event) {
    console.log(event.garget.errorCode)
  }

  request.onsuccess = function (event) {
    var db = this.result;
    var transaction = db.transaction([store], 'readwrite');
    // 
    var objectStore = transaction.objectStore(store);
    var deleteRequest = objectStore.delete(key);
    deleteRequest.onsuccess = function (event) {
      console.log('deleted');
    }
    deleteRequest.onerror = function (event) {
      console.error('deleted', event.target);
    }
    // 
    transaction.onerror = function (event) {
      console.error('fail');
    }
    transaction.onsuccess = function (event) {
      db.close();
      console.log('done');
    }
  }

};
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export const deleteAllData = function () {
  var request = window.indexedDB.open(DATABASE_NAME);

  request.onerror = function (event) {
    console.log(event.garget.errorCode)
  }

  request.onsuccess = function (event) {
    var db = this.result;
    var transaction = db.transaction(['person'], 'readwrite');

    transaction.onerror = function (event) {
      console.log('fail');
    }
    transaction.onsuccess = function (event) {
      console.log('done');
    }
    var objectStore = transaction.objectStore('person');
    var deleteRequest = objectStore.clear();
    deleteRequest.onsuccess = function (event) {
      console.log('deleted');
    }
  }
};
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
