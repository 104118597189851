import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { styled } from '@mui/material/styles';
import ResultDetailBalls from 'components/lotto-result/ResultDetailBalls';
import { getWekkNo2Date } from 'utils/lottoCommon';
import { debug } from 'utils/common';
const DEBUG_TYPE = 'Slip_'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1,
}));


const Slip = (props) => {
  const [slipData, setSlipData] = useState({});
  const [trCode, setTrCode] = useState('');
  const [workDate, setWorkDate] = useState('');
  const [weekNo, setWeekNo] = useState('');
  const [slipBalls, setSlipBalls] = useState([]);

  const handleDetail = useCallback((slipData) => {
    debug(DEBUG_TYPE, 'handleDetail event :', slipData);
    // debug(DEBUG_TYPE, 'handleDetail result :', result);
    props.handleDetail(slipData);
  }, [slipData]);
  const handleDelete = useCallback((trCode) => {
    debug(DEBUG_TYPE, 'handleDelete event :', trCode);
    // debug(DEBUG_TYPE, 'handleDelete result :', result);
    props.handleDelete(trCode);
  }, [trCode]);
  const propSlipDatas = props.slipData.datas;

  useEffect(() => {
    debug(DEBUG_TYPE, '[ Slip ] props ', props);
    setSlipData(props.slipData);
    setTrCode(props.slipData.trcode);
    setWorkDate(props.slipData.workddate);
    setWeekNo(props.slipData.weekno);
    if (props.slipData.datas.length > 0) {
      const newSlipBalls = [...props.slipData.datas]
      setSlipBalls(newSlipBalls);
    }
  }, [props]);

  const paperCss = useMemo(() => {
    return {
      width: '100%',
      backgroundColor: 'lightblue'
    }
  }, [])
  const slipTitleCss = useMemo(() => {
    return {
      fontSize: '12px',
      width: '100%',
      fontWeight: 'bold',
      color: 'blue'
    }
  }, [])
  const slipTitleIconCss = useMemo(() => {
    return {
      fontSize: '12px',
      color: 'orange'  // gold lightblue orange
    }
  }, [])
  return (
    <Paper sx={{ p: '10px' }} style={paperCss}  >
      {/* <span>{slipData.weekNo}회</span> */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
      >
        <AutoAwesomeIcon style={slipTitleIconCss} fontSize='13x' />
        {(slipData.newYn && slipData.newYn === 'N') ? (
          <>
            {slipData.weekNo !== '9999'
              ? <span style={slipTitleCss} >{slipData.weekNo}회 {getWekkNo2Date(slipData.weekNo)} </span>
              : <span style={slipTitleCss} >내 행운번호 </span>
            }
            <ButtonGroup spacing={2} direction="row">
              {slipData.weekNo !== '9999' ?
                <IconButton color="success" aria-label="상세" size="small" onClick={() => handleDetail(slipData)} >
                  <FindInPageTwoToneIcon fontSize="inherit" />
                </IconButton>
                : null}
              <IconButton color="error" aria-label="삭제" size="small" onClick={() => handleDelete(slipData.trCode)}>
                <DeleteForeverTwoToneIcon fontSize="inherit" />
              </IconButton>
            </ButtonGroup>
          </>
        ) : <span style={slipTitleCss} >{slipData.weekNo !== '9999'
          ? <span>전표번호: {slipData.trCode} </span>
          : <span>내행운번호  </span>}
        </span>}
      </Stack>
      {
        (propSlipDatas && propSlipDatas.length > 0) ? (
          propSlipDatas.map((item, index) => (
            <React.Fragment key={item.trcode + '-' + index} >
              <ResultDetailBalls key={item.trcode + '-' + item.seq} balls={item} />
            </React.Fragment>
          ))
        ) : (
          <div>데이타가 존재하지 않습니다. </div>
        )
      }
    </Paper >
  )
}
export default Slip