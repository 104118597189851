import produce from '../utils/produce';
import { debug } from 'utils/common';

const DEBUG_TYPE = 'reducers_home'

export const initialState = {
  loading: true,
  //로또 추첨 번호 
  findLottoLoading: false, //
  findLottoDone: false,
  findLottoError: null,
  //홈화면 - 당첨확인
  confirmDialogOpened: false,
  confirmLottoLoading: false,
  confirmLottoDone: false,
  confirmLottoError: false,
  confirmLottoData: [],
  confirmLottoSaveYn: 'N',
  //홈화면 - 회차 조회하기 .
  findWeekLoading: false,
  findWeekDone: false,
  findWeekError: false,
  confirmWeekData: {},
  weekData: {},
  //홈화면 - 회차 조회하기 .
  findConfirmWeekLoading: false,
  findConfirmWeekDone: false,
  findConfirmWeekError: false,
  //정산내역 조회
  auditLoading: false,
  auditDone: false,
  auditError: false,
  auditData: [],

  // 
  lottoData: [],
  db: null,
  // 
  qrScanData: null,
};
// export const FIND_LOTTO_REQUEST = 'FIND_LOTTO_REQUEST';
// export const FIND_LOTTO_SUCCESS = 'FIND_LOTTO_SUCCESS';
// export const FIND_LOTTO_FAILUR = 'FIND_LOTTO_FAILUR';

// 회차 정보 조회 ( Prev, Next )
export const FIND_WEEK_REQUEST = 'FIND_WEEK_REQUEST';
export const FIND_WEEK_SUCCESS = 'FIND_WEEK_SUCCESS';
export const FIND_WEEK_FAILUR = 'FIND_WEEK_FAILUR';

export const FIND_CONFIRM_WEEK_REQUEST = 'FIND_CONFIRM_WEEK_REQUEST';
export const FIND_CONFIRM_WEEK_SUCCESS = 'FIND_CONFIRM_WEEK_SUCCESS';
export const FIND_CONFIRM_WEEK_FAILUR = 'FIND_CONFIRM_WEEK_FAILUR';


export const SET_LOTTO_REQUEST = 'SET_LOTTO_REQUEST';

export const CONFIRM_DIALOG_OPEN = 'CONFIRM_DIALOG_OPEN';

export const CONFIRM_LOTTO_REQUEST = 'CONFIRM_LOTTO_REQUEST';
export const CONFIRM_LOTTO_SUCCESS = 'CONFIRM_LOTTO_SUCCESS';
export const CONFIRM_LOTTO_FAILUR = 'CONFIRM_LOTTO_FAILUR';

export const CONFIRM_QRSCAN_REQUEST = 'CONFIRM_QRSCAN_REQUEST';
export const CONFIRM_QRSCAN_SUCCESS = 'CONFIRM_QRSCAN_SUCCESS';
export const CONFIRM_QRSCAN_FAILUR = 'CONFIRM_QRSCAN_FAILUR';

export const CONFIRM_AUDIT_REQUEST = 'CONFIRM_AUDIT_REQUEST';
export const CONFIRM_AUDIT_SUCCESS = 'CONFIRM_AUDIT_SUCCESS';
export const CONFIRM_AUDIT_FAILUR = 'CONFIRM_AUDIT_FAILUR';


export const SET_DATABASE = "SET_DATABASE";
export const QR_SCAN_DATA = "QR_SCAN_DATA";


const dummyUser = (data) => ({
  ...data,
  nickname: '제로초',
  id: 1,
  Posts: [{ id: 1 }],
  Followings: [{ nickname: '부기초' }, { nickname: 'Chanho Lee' }, { nickname: 'neue zeal' }],
  Followers: [{ nickname: '부기초' }, { nickname: 'Chanho Lee' }, { nickname: 'neue zeal' }],
});

/*
  1. component 에서 dispatch 호출 
    dispatch({
      type: LOG_IN_REQUEST,
    });
  2. reducer 자동 실행됨. 
  3. reducer 
*/
const reducer = (state = initialState, action) => produce(state, (draft) => {
  debug(DEBUG_TYPE, "[ home reducer ] action.type : ", action.type, action.data);
  switch (action.type) {
    // ---------------------------------------
    // 내번호 페이지에서 자료 삭제시 해당 배열에서 제거하 셋업하기
    // ---------------------------------------
    case SET_LOTTO_REQUEST:
      draft.confirmLottoData = action.data;
      break;
    // ---------------------------------------
    // 확인용 팝업 오픈 여부
    // ---------------------------------------
    case CONFIRM_DIALOG_OPEN:
      draft.confirmDialogOpened = action.data;
      break;
    // ---------------------------------------
    // 정산내역 조회 
    // ---------------------------------------  
    case CONFIRM_AUDIT_REQUEST:
      draft.auditLoading = true;
      draft.auditDone = false;
      draft.auditError = null;
      break;
    case CONFIRM_AUDIT_SUCCESS:
      draft.auditLoading = false;
      draft.auditDone = true;
      draft.auditError = null;
      draft.auditData = action.data;
      break;
    case CONFIRM_AUDIT_FAILUR:
      draft.auditLoading = false;
      draft.auditError = action.error;
      break;
    // ---------------------------------------
    // 확인용 전표를 받아서 당첨 확인용. [ 배열로 전달 ]
    // QR 스캔 데이타도 
    // ---------------------------------------
    case CONFIRM_LOTTO_REQUEST:
      draft.confirmLottoLoading = true;
      draft.confirmLottoDone = false;
      draft.confirmLottoError = null;
      break;
    case CONFIRM_LOTTO_SUCCESS:
      draft.confirmLottoLoading = false;
      draft.confirmLottoDone = true;
      draft.confirmLottoError = null;
      draft.confirmLottoData = action.data;
      break;
    case CONFIRM_LOTTO_FAILUR:
      draft.confirmLottoLoading = false;
      draft.confirmLottoError = action.error;
      break;
    // ---------------------------------------
    // 회차번호로 추첨일자 및 당첨번호 조회. 
    // ---------------------------------------
    case FIND_WEEK_REQUEST:
      draft.findWeekLoading = true;
      draft.findWeekDone = false;
      draft.findWeekError = null;
      break;
    case FIND_WEEK_SUCCESS:
      draft.findWeekLoading = false;
      draft.findWeekDone = true;
      draft.findWeekError = null;
      draft.weekData = action.data;
      break;
    case FIND_CONFIRM_WEEK_SUCCESS:
      draft.findWeekLoading = false;
      draft.findWeekDone = true;
      draft.findWeekError = null;
      draft.confirmWeekData = action.data;
      break;
    case FIND_WEEK_FAILUR:
      draft.findWeekLoading = false;
      draft.findWeekError = action.error;
      break;
    // ---------------------------------------
    case FIND_CONFIRM_WEEK_REQUEST:
      draft.findConfirmWeekLoading = true;
      draft.findConfirmWeekDone = false;
      draft.findConfirmWeekError = null;
      break;
    case FIND_CONFIRM_WEEK_FAILUR:
      draft.findConfirmWeekLoading = false;
      draft.findConfirmWeekError = action.error;
      break;
    case SET_DATABASE:
      draft.db = action.data;
      break;
    default:
      break;
  }
});

export default reducer;
