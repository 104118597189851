import { all, delay, fork, put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import { debug } from 'utils/common';

import {
  FIND_STORE_REQUEST,
  FIND_STORE_SUCCESS,
  FIND_STORE_FAILUR,
} from '../reducers/storelotto';

const DEBUG_TYPE = 'sagas_store_';
function findStoreApi(latitude, longitude) {
  debug(DEBUG_TYPE, ' [ saga ] findStoreApi ', latitude, longitude);
  const responsDatas = axios.get(`/api/lotto/store/${latitude}/${longitude}`);
  return responsDatas;
  // url : nicework.co.kr/api/lotto/store/37.5814329/126.8909878
  // latitude  : 37.5822499205314,
  // longitude : 126.896276025729,
  return axios.post('/api/signUp');
}

function* findStore(action) {
  // console.log('[ Home saga ] findLotto() 호출 ')
  debug(DEBUG_TYPE, ' [ saga ] findStore', action.data);
  try {
    const location = action.data;
    const latitude = location.latitude;
    const longitude = location.longitude;
    const result = yield call(findStoreApi, latitude, longitude);
    debug(DEBUG_TYPE, ' [ saga ] findStore api result : ', result.data);
    yield put({
      type: FIND_STORE_SUCCESS,
      data: result.data
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: FIND_STORE_FAILUR,
      error: err.response.data,
    });
  }
}
function* watchFindStore() {
  yield takeLatest(FIND_STORE_REQUEST, findStore);
}

export default function* userSaga() {
  yield all([
    fork(watchFindStore),
  ]);
}
