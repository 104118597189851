import moment from 'moment';
import 'moment/locale/ko'; // 한국어 설정
import { debug } from './common';


const LOTTO_START_DATE = new Date(2002, 11, 7, 21, 0);
const LOTTO_BUY_AMOUNT = 1000;
const DEBUG_TYPE = 'lottoCommon';
// const DEBUG_TYPE = 'DEBUG';

export const getBallColor = (ballNum) => {
  let rtn;
  if (ballNum >= 1 && ballNum <= 10) {
    // 노랑
    rtn = { backgroundColor: '#f4cf77', color: '#fff' };
  } else if (ballNum >= 11 && ballNum <= 20) {
    // 파랑
    rtn = { backgroundColor: '#6aa7e8', color: '#fff' };
  } else if (ballNum >= 21 && ballNum <= 30) {
    // 빨강
    rtn = { backgroundColor: '#ff7666', color: '#fff' };
  } else if (ballNum >= 31 && ballNum <= 40) {
    // 검정
    rtn = { backgroundColor: '#5a5e62', color: '#fff' };
  } else if (ballNum >= 41 && ballNum <= 45) {
    // 초록
    rtn = { backgroundColor: '#98e187', color: '#fff' };
  }
  return rtn;
};

export const getPreviousSaturday = () => {
  debug(DEBUG_TYPE, '[getPreviousSaturday()] : start');
  const currentDate = new Date();
  // 현재 시간을 가져오고 "hhmm" 형식으로 포맷팅
  const currentTime = moment().format('HHmm');
  const currentDayOfWeek = currentDate.getDay(); // 현재 요일을 가져옵니다. (0: 일요일, 1: 월요일, ..., 6: 토요일)
  debug(DEBUG_TYPE, '[getPreviousSaturday()]  currentDayOfWeek : ', currentDayOfWeek);
  debug(DEBUG_TYPE, '[getPreviousSaturday()]  currentTime : ', currentTime);

  if (currentDayOfWeek === 6) {
    const targetDate = moment(currentDate).set({ hours: 21, minutes: 0 });
    debug(DEBUG_TYPE, '[getPreviousSaturday()]  targetDate : ', targetDate.format('LLLL'));
    if (moment(currentDate).isAfter(targetDate)) {
      return currentDate
    }
  }
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 0', moment(currentDate).subtract(1, 'weeks').day(0).format('LLLL'));
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 1', moment(currentDate).subtract(1, 'weeks').day(1).format('LLLL'));
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 2', moment(currentDate).subtract(1, 'weeks').day(2).format('LLLL'));
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 3', moment(currentDate).subtract(1, 'weeks').day(3).format('LLLL'));
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 4', moment(currentDate).subtract(1, 'weeks').day(4).format('LLLL'));
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 5', moment(currentDate).subtract(1, 'weeks').day(5).format('LLLL'));
  debug(DEBUG_TYPE, '[getPreviousSaturday()] 6', moment(currentDate).subtract(1, 'weeks').day(6).format('LLLL'));
  const lastWeek = moment(currentDate).subtract(1, 'weeks');
  debug(DEBUG_TYPE, '[getPreviousSaturday()] return ', lastWeek.day(6).format('LLLL'));
  return lastWeek.day(6).toDate();
};

export const curWeekNo = () => {
  debug(DEBUG_TYPE, '[curWeekNo()] : start');
  const startDate = LOTTO_START_DATE;
  const prevSaturday = getPreviousSaturday();
  const endDate = moment(prevSaturday).set({ hours: 21, minutes: 0 }).toDate();
  debug(DEBUG_TYPE, '[curWeekNo()] : startDate ', moment(startDate).format('LLLL'));
  debug(DEBUG_TYPE, '[curWeekNo()] : endDate ', moment(endDate).format('LLLL'));

  // 일주일은 7일로 계산되므로 두 날짜의 차이를 7로 나눠줍니다.
  const weekDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24 * 7)) + 1;
  debug(DEBUG_TYPE, '[curWeekNo()] : return value : ', weekDifference);
  return weekDifference;
};

export const getWekkNo2Date = (weekNo) => {
  const weekNoDate = moment(LOTTO_START_DATE).add((weekNo - 1) * 7, 'days');
  // return moment(weekNoDate).format('LL') + '(' + moment().format('dddd') + ')';
  return weekNoDate.format('YYYY년 M월 D일 (ddd)');
}

//===============================================================================================
// 
//===============================================================================================
export const numberPad = (n, width) => {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
};

//===============================================================================================
//  weekNo = 0010 -----> LOTTO_START_DATE('2002-12-07) 기준 10주 뒤 일자를 [ 2003년 02월 08일 (토) ] 포멧으로 리턴.
//===============================================================================================
export const weekNoToDate = (week) => {

  moment.locale('ko'); // 한국어 설정을 적용
  const dateString = '20021207';
  const weekNo = parseInt(week, 10) - 1;  // '0123' ---> 10진수 123 로 변경
  const formattedDate = moment(dateString, 'YYYYMMDD').format('YYYY년 M월 D일');

  const futureDate = moment(dateString, 'YYYYMMDD').add(weekNo, 'weeks');
  const formattedFutureDate = futureDate.format('YYYY년 M월 D일 (ddd)');


  return formattedFutureDate;
}

export const getDatasFromSlip = (slips) => {
  /*
    [
      {trCode:11, datas:[{1,2,3,4,5,6}, {'2,3,4,5,6,7}]}
    , {trCode:11, datas:[{1,2,3,4,5,6}, {'2,3,4,5,6,7}]}
    ]
    상기 배열에서 datas만 뽑아서 배열로 리턴
  */
  const combinedArrayDats = slips.map(item => item.datas).reduce((acc, curr) => acc.concat(curr), [])
  return combinedArrayDats;
}

export const auditProc = (slips) => {
  debug(DEBUG_TYPE, '[auditProc] slips : ', slips);
  // 유형( m:수동, s:반자동, q:자동, f:행운 )
  let audit = [
    { rank: '1등', count: 0, amt: 0 },
    { rank: '2등', count: 0, amt: 0 },
    { rank: '3등', count: 0, amt: 0 },
    { rank: '4등', count: 0, amt: 0 },
    { rank: '5등', count: 0, amt: 0 },
    { rank: '구매', count: 0, amt: 0 },
    { rank: '결산', count: 0, amt: 0 }
  ];
  var winRank = ['1등', '2등', '3등', '4등', '5등', '낙첨'];
  for (let i = 0; i < slips.length; i++) {
    // console.log(`${i}번째 전표 `, slips[i]);
    if (slips[i].weekNo !== '9999') {  // && slips[i].trCode !== '9999909999'
      const datas = slips[i].datas;
      for (let j = 0; j < datas.length; j++) {
        // data.winrank = 1등, 2등, 3등, 4등, 5등
        // data.winamt = 금액 
        // console.log(`${i}번째 전표 ${j}번째 데이타`, datas[j]);
        // numeral(number).format('#,##0');
        for (let k = 0; k < audit.length; k++) {
          if (audit[k].rank === datas[j].winrank) {
            audit[k].count++; // count를 1 증가시킴
            audit[k].amt += datas[j].winamt; // amt를 20000 증가시킴
            break; // 2등을 찾았으므로 루프를 종료
          }
        }
        // if (!datas[j].winrank.includes(winRank)) {
        //   audit[6].count++; // count를 1 증가시킴
        //   audit[6].amt += datas[j].winamt; // amt를 20000 증가시킴
        // }
        // 구매내역 audit[5].rank = 구매
        audit[5].count++; // count를 1 증가시킴
        audit[5].amt += LOTTO_BUY_AMOUNT; // amt를 1000 증가시킴
      }
    }
  }
  const winAmt = audit[0].amt + audit[1].amt + audit[2].amt + audit[3].amt + audit[4].amt
  const buyTotAmt = audit[5].amt
  const auditAmt = winAmt - buyTotAmt
  audit[6].amt = auditAmt
  debug(DEBUG_TYPE, '당첨금액 : ', winAmt)
  debug(DEBUG_TYPE, '구매금액 : ', buyTotAmt)
  debug(DEBUG_TYPE, '결산금액 : ', auditAmt)
  return audit;
}