import React, { useMemo } from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Box, Slider } from '@mui/material';



function Condition(props) {
  const handleSortChange = props.handleSortChange;
  const sort = props.sort;
  const UP = props.UP;
  const DOWN = props.DOWN;
  const Fullscreen = props.Fullscreen;
  const range = props.range;
  const handleSliderChange = props.handleSliderChange;
  const handleSliderChangeCommitted = props.handleSliderChangeCommitted;
  const valuetext = props.valuetext;
  const maxWeekNo = props.maxWeekNo;

  const fontCss = useMemo(() => {
    return {
      fontSize: '12px'
    }
  }, [range])

  return (
    <>
      <Grid container spacing={1} style={{ margin: '10px' }}>
        <Grid item style={{ flex: '0 0 90px' }}>
          <FormControl sx={{ m: 1, minWidth: 80 }} size="small" style={{ marginLeft: 'auto' }}>
            <InputLabel id="demo-select-small-label">Sort</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={sort}
              label="Sort"
              onChange={handleSortChange}
            >
              <MenuItem value={UP}>오름</MenuItem>
              <MenuItem value={DOWN}>내림</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={Fullscreen} style={{ marginTop: '20px', justifyContent: 'right' }}>
          <span sytle={{ width: '60px', fontSize: '10px' }} >스크롤 : 조회기간 설정</span>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item style={{ width: '40px', textAlign: 'left' }}>
              <span style={fontCss}>{range[0]}</span>
            </Grid>
            <Grid item style={{ flex: 1, textAlign: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  value={range}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderChangeCommitted}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={1}
                  max={maxWeekNo}
                />
              </Box>
            </Grid>
            <Grid item style={{ width: '40px', textAlign: 'right' }}>
              <span style={fontCss}>{range[1]}</span>
              {/* <span>{range[1]}</span> */}
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  )
}

export default Condition

