import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';


// 페이지 하단에 고정된 스타일링된 컴포넌트
const FooterWapper = styled(Footer)`
  position: fixed;
  bottom: 0;
  width: 100%;
`;
const DefaultLayout = () => {
  return (
    <div>
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
