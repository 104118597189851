import React, { useState, useEffect } from 'react';
// import { initDatabase, insertData, DeleteData , findData } from 'utils/dbCommon';
import { findData, LOTTO_MINE } from 'utils/dbCommon';
import { Container, List, ListItem } from '@mui/material';
import { Grid } from '@mui/material';
import { CONFIRM_LOTTO_REQUEST, CONFIRM_QRSCAN_REQUEST, confirmLottoData } from 'reducers/home';


import { useSelector, useDispatch } from 'react-redux';

import Slip from 'components/lotto-mynum/Slip';

/*
  사용 예제 
  initDatabase : 처음 App 실행시 데이타베이스 및 스토어 만들기 
  insertData(storeName, key, data) : data를 store에 저장하기 
    ex) insertData('LOTTO_MINE', 'trcode', mylotto);
  findData( store, indexName, indexKey , onSuccess, onError)
    ex)  findData( 'LOTTO_MINE'
      ,'key', '%' 
      , function(data){     // --> onSuccess
          if ( data ) {
            // 데이타를 찾은 경우 
            console.log('data found : ' , data);
          } else {
            // 데이타를 못 찾을 경우 
            console.log('data not found. ');
          }
        }
      , function(error){    // --> onError
          // 에러 발생시 
          console.log('Error occurred:', error);
        }
     );
  deleteData (store, key )
    ex) deleteData('LOTTO_MINE','10002' )
*/
const DbCRUD = () => {
  const [items, setItems] = useState([]);
  const [slipDatas, setSlipDatas] = useState([]);
  // Store 변수.
  const {
    confirmLottoData,
  } = useSelector(state => state.home);  // Array Type

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      // const allItems = ();
      // setItems(allItems);
    };

    fetchData();
  }, []);
  useEffect(() => {
    console.log('confconfirmLottoData 변화 감지', confirmLottoData)
  }, [confirmLottoData]);

  const handleFind = async () => {
    console.log('find 버튼 클릭')
    // async function asyncFindData() {
    //   const resultData = await findData(LOTTO_MINE, 'key', '%');
    //   return resultData;
    // }
    const keyName = 'key';
    const key = '%'
    const saveDatas = await findData(LOTTO_MINE, keyName, key);
    console.log('finded result', saveDatas)
    dispatch({
      type: CONFIRM_LOTTO_REQUEST,
      data: saveDatas
    });

    let audit = [
      { rank: '1등', count: 0, amt: 0 },
      { rank: '2등', count: 0, amt: 0 },
      { rank: '3등', count: 0, amt: 0 },
      { rank: '4등', count: 0, amt: 0 },
      { rank: '5등', count: 0, amt: 0 },
      { rank: '낙첨', count: 0, amt: 0 },
      { rank: '미추첨', count: 0, amt: 0 }
    ];
    for (let i = 0; i < saveDatas.length; i++) {
      console.log(`${i}번째 전표 `, saveDatas[i]);
      if (saveDatas[i].winYn === 'Y') {
        const datas = saveDatas[i].datas;
        for (let j = 0; j < datas.length; j++) {
          // data.winrank = 1등, 2등, 3등, 4등, 5등
          // data.winamt = 금액 
          // console.log(`${i}번째 전표 ${j}번째 데이타`, datas[j]);
          for (let k = 0; k < audit.length; k++) {
            if (audit[k].rank === datas[j].winrank) {
              audit[k].count++; // count를 1 증가시킴
              audit[k].amt += datas[j].winamt; // amt를 20000 증가시킴
              break; // 2등을 찾았으므로 루프를 종료
            }
          }
        }
      }
      saveDatas[i].newYn = 'N'
      console.log('audit', audit)
    }
    // console.log('modified result', saveDatas)
    setSlipDatas(saveDatas);
    // console.log('audit value ', audit)
  }

  const handleAddItem = async () => {
    const newItem = { name: 'New Item' };
    // await createItem(newItem);
    // const allItems = await readAllItems();
    // setItems(allItems);
  };

  const handleUpdateItem = async (id) => {
    // const updatedItem = { id, name: 'Updated Item' };
    // await updateItem(id, updatedItem);
    // const allItems = await readAllItems();
    // setItems(allItems);
  };

  const handleDeleteItem = async (id) => {
    // await deleteItem(id);
    // const allItems = await readAllItems();
    // setItems(allItems);
  };

  return (
    <div>
      <button onClick={handleFind}>Find</button>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            {item.name}
            <button onClick={() => handleUpdateItem(item.id)}>Update</button>
            <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
          </li>
        ))}
      </ul>
      <Container>
        <List>
          {
            confirmLottoData.length > 0 ? (
              confirmLottoData.map((item, index) => (
                <ListItem key={item.trCode + index} sx={{ px: '0px' }}>
                  <Slip key={item.trCode} slipData={item} />
                </ListItem>
              ))
            ) : (
              <div>자료가 존재하지 않습니다. WeekWinConfirm component</div>
            )
          }
        </List>
      </Container>
      <Grid container spacing={2}>
        {/* 첫 번째 아이템 - 가로 길이 100px */}
        <Grid item>
          <div style={{ backgroundColor: 'lightblue', height: '100px', width: '100px' }}>Item 1</div>
        </Grid>

        {/* 두 번째 아이템 - 가로 길이 20px */}
        <Grid item xs={2}>
          <div style={{ backgroundColor: 'lightgreen', height: '100px' }}>Item 2</div>
        </Grid>

        {/* 세 번째 아이템 - 나머지 공간 사용 */}
        <Grid item xs>
          <div style={{ backgroundColor: 'lightcoral', height: '100px' }}>Item 3</div>
        </Grid>
      </Grid>


    </div>
  );
};
export default DbCRUD;