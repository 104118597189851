import React, { useMemo, useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid'; // Grid version 1
// import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import ResultBalls from './ResultBalls';
import Week from './Week';

import { debug } from 'utils/common';
import { PREV_BTN_COLOR, NEXT_BTN_COLOR } from 'utils/constValue';
const DEBUG_TYPE = 'RESULT_CONTENT';

const ResultBallWrapper = styled(Box)`
  margin-top: 20px;
`;

const ResultContent = (props) => {
  const [history, setHistory] = useState();
  const [weekData, setWeekData] = useState({});
  const handleForward = props.handleForward;
  const handleBackward = props.handleBackward;

  useEffect(() => {
    debug(DEBUG_TYPE, '[ ResultContent ] props', props)
    setHistory(props.history);
    setWeekData(props.weekData);
  }, [props])

  const prevBtnStyle = useMemo(() => {
    return {
      fontSize: '36px',
      color: PREV_BTN_COLOR, // 원하는 텍스트 색상
    }
  }, []);
  const nextBtnStyle = useMemo(() => {
    return {
      fontSize: '36px',
      color: NEXT_BTN_COLOR, // 원하는 텍스트 색상
    }
  }, []);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="right"
            alignItems="center">
            {history ? <ArrowCircleLeftIcon style={prevBtnStyle} onClick={handleBackward} /> : null}
          </Grid>
          <Grid
            item
            xs={8}>
            <Week weekData={weekData} />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="left"
            alignItems="center">
            {history ? <ArrowCircleRightIcon style={nextBtnStyle} onClick={handleForward} /> : null}
          </Grid>
        </Grid>
      </Box>
      <ResultBallWrapper sx={{ flexGrow: 1 }} >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            {(weekData.winyn === 'Y') ? <ResultBalls weekData={weekData} />
              : <span>당첨번호를 추첨하지 않았습니다.</span>}

          </Grid>
        </Grid>
      </ResultBallWrapper>


    </div>
  )


}

export default ResultContent