import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const dataOrg = [
  {
    name: '06-07',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '06-08',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: '06-08',
    uv: 2000,
    pv: 9800,
    amt: 2900
  },
  {
    name: '06-08',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '06-08',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '06-08',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '06-08',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const MonthlyChart = () => {
  const data = dataOrg;
  console.log('data', data);
  console.log('dataOrg', dataOrg);
  const handleBtnClicked = () => {
    console.log('today = ');
  };
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Line type="monotone" dataKey="y" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
          <Line type="monotone" dataKey="Budget" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Sales" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <button onClick={handleBtnClicked}>test</button>
    </>
  );
};

export default MonthlyChart;