
import store from 'store/store';
import { QR_SCAN_DATA } from 'reducers/home';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { numberPad } from "./lottoCommon";
import { debug } from 'utils/common';

const DEBUG_TYPE = 'brige'
const PC_OS = "win16|win32|win64|mac|macintel";

export const deviceWhat = async () => {
  const userAgent = await navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (PC_OS.indexOf(userAgent) > 0) {
    return 'other';
  }
  // debug(DEBUG_TYPE, 'userAgent.indexOf(android) : ', userAgent.indexOf('android'))
  if (userAgent.indexOf('android') > -1) {
    return 'android'
  } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf("ipod") > -1) {
    //console.log("ios call")
    return 'ios'
  } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
    return 'other'
  }
}

//===================================================================================
//  요청 : 웹 cameraScan ()
//         ---> 요청 : Android.camerScan() ( 안드로이드 네이티브 함수 호출 )
//         ---> 응답 : 안드로이드 callBack(latitude, longitude)
//===================================================================================
export const cameraScan = async () => {
  const device = await deviceWhat();
  debug(DEBUG_TYPE, ' [ cameraScan() ] device', device);
  if (device === "android") {
    try {
      // eslint-disable-next-line no-undef
      Android.camerScan();
    } catch (err) {

    }
  } else if (device === "ios") {
    //console.log("BeaconSearch : ios");
    try {
      //window.webkit.messageHandlers.getBeaconList.postMessage("MessageBody");
      const scanData = "http://m.dhlottery.co.kr/?v=0941m030912243745q0102030405069999900004";
      window.callBack(scanData, "QR");
    } catch (err) {

    }
  } else {
    // 뒤에서 10자리 전표번호 : 1,206,400,705
    // 1081 : 1 , 9 , 16, 23, 24, 38
    // 1080 : 13, 16, 23, 31, 36, 44
    // 1079 : 4, 8, 18, 24, 37, 45
    let scanData = "";
    // 1등  
    // scanData = "http://m.dhlottery.co.kr/?v=1081m010916232438m010916232445q011116233138q011617232438q1016222938391206400705";
    // scanData = "http://m.dhlottery.co.kr/?v=1081m030817202735m030817202635s040817202735q050819202735q0309192227359999900005";  // 1등 ~ 5등
    // scanData = "http://m.dhlottery.co.kr/?v=1081m030912243745m030912243745s030621222530q080913244344q0102030405069999900056";
    // scanData = "http://m.dhlottery.co.kr/?v=1080m101218040506m030912184243s101218354245q101218354243q1012183539429999900001";
    // scanData = "http://m.dhlottery.co.kr/?v=1079m101218040506m030912184243s101218354245q101218354243q1012183539429999900003";
    // scanData = "http://m.dhlottery.co.kr/?v=0948m101218040506m030912184243s101218354245q101218354243q1012183539429999900048";
    // scanData = "http://m.dhlottery.co.kr/?v=1079m030912243745q0102030405069999900004";
    scanData = "http://m.dhlottery.co.kr/?v=1095m030912243745q0102032126279999900114";
    // scanData = "http://m.dhlottery.co.kr/?v=1084m030912243745q0102030405069999900115";
    // scanData = "http://m.dhlottery.co.kr/?v=0948m101218040506m030912184243s101218354245q101218354243q1012183539429999900048";
    debug(DEBUG_TYPE, "[brige] cameraScan - window.callBack() : ", scanData)

    // window.callback(scanData,'QR') 함수 호출은 안드리이드 폰에서 호출하게 됨. 
    window.CallBack(scanData, 'QR')
    // store.dispatch({
    //   type: QR_SCAN_DATA,
    //   data: scanData,
    // });
    // return scanData;
  }
}

/*■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
G-3. 안드로이(아이폰)에서 호출 하는 함수
      rtn_data :  http://m.dhlottery.co.kr/?v=0942m030912243745m030912243745q030621222530q080913244344q1016222938391206400705
     2개 번호만 있는 경우
     rtn_data :  http://m.dhlottery.co.kr/?v=0961q141624283139q021320303942n000000000000n000000000000n0000000000001762783971"

  실행할 컴포넌트에서 실행하기 
  // 브리지 설정
  useEffect(() => {
    // 브리지 함수를 전역으로 등록하여 웹뷰에서 호출할 수 있게 만듦
    window.bridgeFunction = bridgeFunction;

    return () => {
      // 컴포넌트가 언마운트될 때 브리지 함수 해제
      delete window.bridgeFunction;
    };
  }, []);
  위 코드에서 bridgeFunction은 브리지를 통해 호출될 함수입니다.
  useEffect를 사용하여 컴포넌트가 마운트될 때, window.bridgeFunction에 bridgeFunction 함수를 등록하고, 언마운트될 때 해제합니다.

  이제 안드로이드 웹뷰에서 window.bridgeFunction을 호출하면 React 컴포넌트의 bridgeFunction 함수가 실행됩니다. 
  웹뷰에서 브리지를 통해 데이터를 전달할 수 있으며, 이를 활용하여 안드로이드 앱과 React 컴포넌트 간에 상호작용할 수 있습니다.

  또한, React 컴포넌트에서 bridgeFunction 함수를 호출할 수 있도록 브리지를 통해 안드로이드 앱에서 함수를 등록해주어야 합니다. 
  이는 안드로이드 쪽의 WebView 설정에서 이루어지며, 자세한 내용은 안드로이드 개발 문서나 해당 프로젝트의 WebView 관련 설정을 참고하시기 바랍니다.

■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■*/
export const callBack_bak = (rtn_data, type) => {
  // calBack_bak 함수는 사용하는 컴포넌트에서 선언하여 사용하면 된다.
  debug(DEBUG_TYPE, "[brige] callBack (data, type)", rtn_data, type)
  let dataArray = rtn_data.split('?');
  for (let i = 0; i < dataArray.length; i++) {
    debug(DEBUG_TYPE, `[brige] callBack dataArray[${i}]`, dataArray[i]);
  };
  // 파리미터 값을 구분자 "=" 으로 나누어 배열로 만든다.
  let parmArray = dataArray[1].split('=');
  for (let i = 0; i < parmArray.length; i++) {
    debug(DEBUG_TYPE, `[brige] callBack parmArray[${i}]`, parmArray[i]);
  };

  let parm = parmArray[1];  // 값 : 0942m030912243745m030912243745q030621222530q080913244344q1016222938391206400705
  return parm;
};
//=================================================================
//	G-4. 데이타를 분석하여 값들을 분석 후 파싱하여 배열로 리턴한다.
//       리턴값 : 2차배열
//=================================================================
export const dataParser = (data) => {
  // debug == 'Y' ? console.log('brige.js dataParser() 호출') : null;
  debug(DEBUG_TYPE, '[brige] dataParser() 호출', data)
  // 파리미터 값을 구분자 "=" 으로 나누어 배열로 만든다.
  let dataSplitArray = data.split('=');
  for (let i = 0; i < dataSplitArray.length; i++) {
    debug(DEBUG_TYPE, `[brige] callBack dataSplitArray[${i}]`, dataSplitArray[i]);
  };
  /*-------------------------------------------------------------------------------------------
  result : success, fail
  data   : [{	string		trcode    : 전표번호
              string		weekno    : 추첨횟차
              string		weekdate  : 추첨일자 ( 2021년01월02일 (토) )
              string		winyn	    : ?		 [ not used ]
              string		savedata  : QR 데이타 [ not used ]
              integer		seq 	    : 순번
              string		type      : 유형( m:수동, s:반자동, q:자동, f:행운 )
        갱신  string		num1 	    : 숫자 1
        갱신  string		num2 	    : 숫자 2
        갱신  string		num3 	    : 숫자 3
        갱신  string		num4      : 숫자 4
        갱신	string		num5      : 숫자 5
        갱신	string		num6      : 숫자 6
        갱신	string		winrank	  : 해당횟차 당첨내역(1등, 2등, 3등, 4등, 5등. 낙첨)
        갱신	integer		winamt	  : 해당횟차 당첨금액
        갱신	integer		rwin1	    : 해당번호 rweekno 회차까지 1등 당첨 횟수
        갱신	integer		rwin2     : 해당번호 rweekno 회차까지 2등 당첨 횟수
        갱신	integer		rwin3     : 해당번호 rweekno 회차까지 3등 당첨 횟수
        갱신	integer   rwin4     : 해당번호 rweekno 회차까지 4등 당첨 횟수
        갱신	integer		rwin5     : 해당번호 rweekno 회차까지 5등 당첨 횟수
              string	 rweekno   : 당첨확인 마직막 횟차
              string	 workdate  : 자료 조회순번 [weekno + 작업일자 ]
              integer	 trseq 	   : 해당전표의 순차적 순번[1...] , seq는 전표내 해당번호의 구분값[key]으므로 꼭 순차적이지 않고, 이건 1번부터 차례대로 부여받은 번호 '
              integer  trmaxseq  : 해당전표의 번호 갯수 [ trseq 의 최대값 ]
              string 	 newyn     : 해당전표가 새로운 것인지 아닌지 [ 저장버튼 활성/비활성화 기준 ]

        추가	string		winresult : 과거 당첨내역 5등 26회, 4등 1회
      }, {}, {}]
  ------------------------------------------------------------------------------------------------*/
  // 값 : 0942m030912243745m030912243745q030621222530q080913244344q1016222938391206400705
  var parm = dataSplitArray[1];
  //문자 자르기
  var weekno = parm.substring(0, 4);
  var parm = parm.substring(4, parm.length); // 값 : m030912243745m030912243745q030621222530q080913244344q1016222938391206400705
  // 전표에 구매 건수 [ 1건의 구매자료 포맷(총 13자리) : 구매타입(1자리) + { 번호(2자리) * 6 = 12 }  ]
  var cntArry = Math.floor(parm.length / 13);
  // 마지막 전표번호 [ 10자리 ]
  var trcode = parm.substring((cntArry * 13), parm.length);
  // 전역변수 초기화
  var datas = new Array();
  // 구매건수별로 전표번호를 자른다.
  for (let i = 0; i < cntArry; i++) {
    let sPoint = i * 13; // 0 , 13, 26, 39, 52
    let sLength = 13; //
    let numData = parm.substr(sPoint, sLength);
    let data = new Object();
    data.trcode = trcode + "";
    data.weekno = weekno + "";
    data.weekdate = "";
    data.winyn = "N";
    data.savedata = "QR";
    data.seq = i + 1;

    data.type = numData.substr(0, 1);
    data.num1 = numberPad(numData.substr(1, 2), 3);
    data.num2 = numberPad(numData.substr(3, 2), 3);
    data.num3 = numberPad(numData.substr(5, 2), 3);
    data.num4 = numberPad(numData.substr(7, 2), 3);
    data.num5 = numberPad(numData.substr(9, 2), 3);
    data.num6 = numberPad(numData.substr(11, 2), 3);
    data.winrank = getTrType(data.type);  // 공통함수의 번호 선택타입 가져와 세팅 ( 유형( m:수동, s:반자동, q:자동, f:행운 ) )
    data.winamt = 0;
    data.rwin1 = 0;
    data.rwin2 = 0;
    data.rwin3 = 0;
    data.rwin4 = 0;
    data.rwin5 = 0;
    data.rweekno = "0000";
    data.workdate = weekno + "_" + moment().format('YYYYMMDD')
    data.trseq = (i + 1);
    data.trseqmax = cntArry;
    data.newyn = "Y";			// QR로 등록되는 경우
    data.winresult = "";
    if (data.num1 !== "000") {
      datas.push(data);
    }
  }
  let jsonObject = {}
  jsonObject.trCode = trcode;
  jsonObject.weekNo = weekno;
  jsonObject.workDate = weekno + "_" + moment().format('YYYYMMDD');
  jsonObject.winYn = 'N';
  jsonObject.weekDate = '';
  jsonObject.newYn = 'Y';
  jsonObject.datas = datas;
  debug(DEBUG_TYPE, '[brige] dataParser() json Return Value ', jsonObject)
  return jsonObject;
};

// qr스캔한 전표의 번호 선택 타입
export const getTrType = (type) => {
  // m: 수동, s: 반자동, q: 자동, f: 행운
  let rtn = "";
  if (type === "m") {
    rtn = "수동";
  } else if (type === "s") {
    rtn = "반자동";
  } else if (type === "q") {
    rtn = "자동";
  } else {
    rtn = "  ";
  }
  return rtn;
};